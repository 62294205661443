import { ExclamationCircleOutlined, RocketOutlined } from '@ant-design/icons'
import CountryFlagIcon from '@components/CountryFlagIcon'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { useCheckPermissions } from '@components/Permissions/useCheckPermissions'
import { DEFAULT_LANGUAGE } from '@constants'
import { NAME_KEY } from '@constants/fieldKeys'
import { useSiteContext } from '@contexts/site'
import { getPropertyValue } from '@modules/property'
import StorageHelper from '@modules/storage'
import { setSearchParam } from '@utils'
import { permissions } from '@wdnsolutions/auth-helpers'
import { Dropdown, Input, Menu } from 'antd'
import classNames from 'classnames'
import React, { memo, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styles from '../style.module.scss'
import { SITE_URL_KEY, SITE_USER_SELECT_STORE_KEY } from './constants'
import { filterSitesBySearch } from './methods'

const SiteSelect = () => {
  const {
    state: { sites, selectorConfig },
    getCurrentSite,
    getCurrentLanguage,
  } = useSiteContext()
  const currentSite = getCurrentSite()
  const language = getCurrentLanguage()

  const [search, setSearch] = useState('')

  const menu = useRenderMenu({
    sites,
    currentSite,
    search,
    setSearch,
  })

  return (
    <Dropdown
      disabled={selectorConfig.disabled}
      overlay={menu}
      trigger={['hover']}
      placement="bottomLeft">
      <div
        className={classNames(
          styles.dropdown,
          selectorConfig.disabled && styles.disabled,
        )}>
        {renderSite(currentSite)}
        {renderLanguage(language)}
      </div>
    </Dropdown>
  )
}

export default memo(SiteSelect)

function renderSite(currentSite) {
  if (!currentSite) {
    return <span className="text-uppercase text-white">All sites</span>
  }

  return (
    <span className="badge badge-pill badge-light d-inline small">
      {getPropertyValue(NAME_KEY, currentSite?.properties)}
    </span>
  )
}

function renderLanguage(language) {
  return (
    <CountryFlagIcon
      countryCode={language.countryCode}
      style={{ marginLeft: '5px' }}
    />
  )
}

function useRenderMenu({ sites, currentSite, search, setSearch }) {
  const history = useHistory()
  const isSiteCreateAllowed = useCheckPermissions([permissions.site.create])

  const menuItems = useMemo(() => {
    const handleSearch = (value: string): void => {
      setSearch(value)
    }

    const handleSearchChange = ({ target: { value } }): void => {
      setSearch(value)
    }

    const menuItems: any[] = [
      {
        key: 'all',
        label: 'All sites',
      },
      { key: 'divider1', type: 'divider' },
      {
        key: 'search',
        disabled: true,
        label: (
          <Input.Search
            allowClear
            value={search}
            placeholder="Type to filter"
            onSearch={handleSearch}
            onChange={handleSearchChange}
          />
        ),
      },
    ]

    const sitesToRender = search
      ? filterSitesBySearch(sites, search)
      : [...sites]

    sitesToRender
      ?.sort((a, b) => {
        const aSiteUrl = getPropertyValue(NAME_KEY, a?.properties) || ''
        const bSiteUrl = getPropertyValue(NAME_KEY, b?.properties) || ''

        return aSiteUrl.localeCompare(bSiteUrl)
      })
      .reduce((enFirstSortedSites, site) => {
        if (site.language.languageTag === DEFAULT_LANGUAGE.languageTag) {
          enFirstSortedSites.unshift(site)
        } else {
          enFirstSortedSites.push(site)
        }

        return enFirstSortedSites
      }, [])
      .forEach(site => {
        menuItems.push({
          key: site.id,
          label: (
            <>
              <CountryFlagIcon
                countryCode={site.language?.countryCode}
                style={{ marginRight: '10px' }}
              />
              {getPropertyValue(NAME_KEY, site?.properties)}
            </>
          ),
        })
      })

    if (sitesToRender?.length) {
      menuItems.push({ key: 'divider2', type: 'divider' })
    }

    if (!sitesToRender?.length) {
      menuItems.push({
        key: 'noContent',
        disabled: true,
        icon: <ExclamationCircleOutlined />,
        label: <span style={{ fontWeight: 500 }}>No data available</span>,
      })
    }

    if (isSiteCreateAllowed) {
      menuItems.push({
        key: 'add',
        label: (
          <LinkWithQuery to="/sites/add">
            <RocketOutlined className="mr-2" /> Add site
          </LinkWithQuery>
        ),
      })
    }

    return menuItems
  }, [isSiteCreateAllowed, sites, search, setSearch])

  return (
    <Menu
      selectable={false}
      selectedKeys={[currentSite?.id]}
      onClick={({ key }) => {
        setSearch('')
        if (key === 'add') {
          return
        }

        const site = sites.find(site => site.id === key)
        StorageHelper.setItem(
          SITE_USER_SELECT_STORE_KEY,
          site?.language?.languageTag || key,
        )

        setSearchParam(history, SITE_URL_KEY, key)
      }}
      items={menuItems}
    />
  )
}
