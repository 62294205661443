import {
  DEFAULT_KEY_FOR_CONTEXT,
  getAdditionalFlagValue,
  getKeyForValue,
} from '@contexts/hooks/usePersistSelector'
import { getSearchParam, setSearchParam } from '@utils'
import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useContextURLConnection = ({
  loading,
  getCurrent,
  setCurrent,
  validate,
  getDefault,
  searchParamKey,
}) => {
  const history = useHistory()
  const location = useLocation()

  const prevURL = useRef('non existent ID')

  useEffect(() => {
    if (loading) {
      return
    }

    let urlParam = getSearchParam(location.search, searchParamKey)

    let additionalKeyOrId = getKeyForValue(urlParam)
    if (additionalKeyOrId === undefined) {
      // urlParam is ID
      additionalKeyOrId = urlParam
    }

    const current = getCurrent()

    if (additionalKeyOrId === current) {
      prevURL.current = urlParam
      return
    }

    if (prevURL.current !== urlParam) {
      // url changed
      if (validate(additionalKeyOrId)) {
        prevURL.current = urlParam
        setCurrent(additionalKeyOrId)
      } else {
        setSearchParam(
          history,
          searchParamKey,
          getAdditionalFlagValue(DEFAULT_KEY_FOR_CONTEXT),
        )
      }
      return
    }

    // context value changed
    if (!validate(current)) {
      setCurrent(getDefault())
      return
    }

    let urlValue = getAdditionalFlagValue(current)
    if (urlValue === undefined) {
      urlValue = current
    }

    prevURL.current = urlValue
    setSearchParam(history, searchParamKey, prevURL.current)
  }, [
    getCurrent,
    history,
    location.search,
    loading,
    validate,
    setCurrent,
    getDefault,
    searchParamKey,
  ])
}
