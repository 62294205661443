import { DEFAULT_LANGUAGE } from '@constants'
import { useSettingsContext } from '@contexts/settings'
import { ConfigProvider } from 'antd'
import React from 'react'
import { IntlProvider } from 'react-intl'

import english from '../../../locales/en-US'
import french from '../../../locales/fr-FR'
import russian from '../../../locales/ru-RU'
import chinese from '../../../locales/zh-CN'

const languages = {
  en: english,
  fr: french,
  ru: russian,
  zh: chinese,
}

const Localization = ({ children }) => {
  const {
    state: { language },
  } = useSettingsContext()
  const currentLanguage =
    languages[language] || languages[DEFAULT_LANGUAGE.languageTag]

  return (
    <ConfigProvider locale={currentLanguage.antdData}>
      <IntlProvider
        locale={currentLanguage.locale}
        messages={currentLanguage.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default Localization
