import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
  notifications: [],
}

const NotificationContext = createContext()

export const useNotificationContext = () => useContext(NotificationContext)

const removeItemsByKey = (array, key, value) => {
  return array.filter(item => !(item[key] === value))
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'push_notification':
      return {
        notifications: [
          ...removeItemsByKey(state.notifications, 'id', action.payload.id),
          action.payload,
        ],
      }
    case 'remove_notification':
      return {
        notifications: removeItemsByKey(
          state.notifications,
          'id',
          action.payload,
        ),
      }
    default:
      throw new Error()
  }
}

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const pushNotification = notification => {
    if (notification) {
      return dispatch({
        type: 'push_notification',
        payload: notification,
      })
    }
  }

  const removeNotification = id => {
    if (id) {
      return dispatch({
        type: 'remove_notification',
        payload: id,
      })
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        state,
        pushNotification,
        removeNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
