import flat from 'flat'

import * as permissions from '../permissions'

export type Role = {
  name: string
  permissions: Array<any>
}

export default [
  {
    name: 'admin',
    permissions: Object.values(flat.flatten(permissions)),
  },
  {
    name: 'editor',
    permissions: [
      permissions.site.view,
      permissions.provider.view,
      permissions.provider.publisher.create,
      permissions.provider.publisher.edit,
      permissions.provider.publisher.delete,
      permissions.taxonomy.view,
      permissions.taxonomy.publisher.create,
      permissions.taxonomy.publisher.edit,
      permissions.taxonomy.publisher.delete,
      permissions.filterTaxonomy.view,
      permissions.filterTaxonomy.create,
      permissions.filterTaxonomy.edit,
      permissions.filterTaxonomy.delete,
      permissions.product.view,
      permissions.product.edit,
      permissions.product.create,
      permissions.product.delete,
      permissions.author.view,
      permissions.author.create,
      permissions.author.delete,
      permissions.author.edit,
      permissions.post.view,
      permissions.post.publisher.create,
      permissions.post.publisher.edit,
      permissions.post.publisher.delete,
      permissions.page.view,
      permissions.page.create,
      permissions.page.edit,
      permissions.page.delete,
      permissions.export.view,
      permissions.status.view,
      permissions.link.edit,
      permissions.link.create,
      permissions.link.delete,
      permissions.link.view,
      permissions.rank.view,
      permissions.rank.create,
      permissions.rank.delete,
      permissions.rank.edit,
      permissions.network.view,
      permissions.vertical.view,
      permissions.menu.view,
      permissions.menu.create,
      permissions.menu.delete,
      permissions.menu.edit,
      permissions.translation.view,
      permissions.translation.create,
      permissions.translation.delete,
      permissions.translation.edit,
      permissions.sitemap.view,
    ],
  },
  {
    name: 'viewer',
    permissions: [
      permissions.provider.view,
      permissions.post.view,
      permissions.page.view,
      permissions.product.view,
      permissions.taxonomy.view,
      permissions.filterTaxonomy.view,
    ],
  },
] as Array<Role>
