import { NETWORK_URL_KEY } from '@components/LayoutComponents/TopBar/NetworkSelect/constants'
import { SITE_URL_KEY } from '@components/LayoutComponents/TopBar/SiteSelect/constants'
import { VERTICAL_URL_KEY } from '@components/LayoutComponents/TopBar/VerticalSelect/constants'
import {
  checkAdditionalFlag,
  getAdditionalFlagValue,
} from '@contexts/hooks/usePersistSelector'
import {
  getSelectedValue as getNetworkSelectedValue,
  useNetworkContext,
} from '@contexts/network'
import {
  getSelectedValue as getSiteSelectedValue,
  useSiteContext,
} from '@contexts/site'
import {
  getSelectedValue as getVerticalSelectedValue,
  useVerticalContext,
} from '@contexts/vertical'
import { setSearchParam } from '@utils'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function useTopSelector(topSelector?: TopSelector) {
  const { loading: networkLoading, setSelectorConfig: setNetworkConfig } =
    useNetworkContext()

  useSetSelectorConfig(
    topSelector?.network,
    NETWORK_URL_KEY,
    setNetworkConfig,
    getNetworkSelectedValue,
    networkLoading,
  )

  const { loading: verticalLoading, setSelectorConfig: setVerticalConfig } =
    useVerticalContext()

  useSetSelectorConfig(
    topSelector?.vertical,
    VERTICAL_URL_KEY,
    setVerticalConfig,
    getVerticalSelectedValue,
    verticalLoading,
  )

  const {
    loading: siteLoading,
    state: { sites },
    setSelectorConfig: setSiteConfig,
  } = useSiteContext()

  const getSiteValueCurried = useCallback(() => {
    const siteValue = getSiteSelectedValue(sites)
    if (checkAdditionalFlag(siteValue)) {
      return getAdditionalFlagValue(siteValue)
    }
    return siteValue
  }, [sites])

  useSetSelectorConfig(
    topSelector?.site,
    SITE_URL_KEY,
    setSiteConfig,
    getSiteValueCurried,
    siteLoading,
  )
}

function useSetSelectorConfig(
  selectorConfig: BeginState,
  searchParamKey: string,
  setConfig: (config) => void,
  getSelectorValue: () => string,
  loading: boolean,
) {
  const history = useHistory()

  useEffect(() => {
    if (loading) {
      return
    }
    const config = selectorConfig || {}
    setConfig(config)

    if (config.selected !== undefined) {
      setSearchParam(history, searchParamKey, config.selected)
      return
    }

    if (!config.disabled) {
      setSearchParam(history, searchParamKey, getSelectorValue())
    }
  }, [
    loading,
    selectorConfig,
    setConfig,
    history,
    getSelectorValue,
    searchParamKey,
  ])
}

interface TopSelector {
  network: BeginState
  vertical: BeginState
  site: BeginState
}

type BeginState = {
  selected?: null
  disabled?: boolean
}
