import { useAuth0 } from '@auth0/auth0-react'
import { NETWORK_USER_SELECT_STORE_KEY } from '@components/LayoutComponents/TopBar/NetworkSelect/constants'
import { useLanguagesContext } from '@contexts/languages'
import { useNetworkContextSlimNetworksLazyQuery } from '@generated/graphql'
import StorageHelper from '@modules/storage'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

import {
  checkAdditionalFlag,
  DEFAULT_KEY_FOR_CONTEXT,
  getPersistedSelector,
  usePersistSelector,
} from './hooks/usePersistSelector'

const STORAGE_KEY = 'currentNetwork'

function reducer(state, action) {
  switch (action.type) {
    case 'set_networks':
      return { ...state, networks: action.payload, loading: false }
    case 'set_current_network':
      if (state.currentNetwork === action.payload) {
        return state
      }
      return { ...state, currentNetwork: action.payload }
    case 'set_loading':
      if (action.payload === state.loading) {
        return state
      }
      return { ...state, loading: action.payload }
    case 'set_selector_config':
      if (
        action.payload.selected === state.selectorConfig.selected &&
        action.payload.disabled === state.selectorConfig.disabled
      ) {
        return state
      }
      return { ...state, selectorConfig: action.payload }
    default:
      throw new Error()
  }
}

const NetworkContext = createContext()

export const useNetworkContext = () => useContext(NetworkContext)

function init() {
  return {
    networks: [],
    currentNetwork: getPersistedSelector(STORAGE_KEY),
    loading: true,
    selectorConfig: {},
  }
}

const NetworkProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const { defaultLanguageId } = useLanguagesContext()

  const [state, dispatch] = useReducer(reducer, null, init)
  usePersistSelector(STORAGE_KEY, state.currentNetwork)

  const setCurrentNetwork = useCallback(id => {
    dispatch({
      type: 'set_current_network',
      payload: id,
    })
  }, [])

  const [queryNetworks, { refetch }] = useNetworkContextSlimNetworksLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ networks }) => {
      console.log(
        '%c Allan: Finished loading networks ',
        'background: #222; color: #bada55',
      )

      setNetworks(dispatch, networks)
    },
    onError: error => {
      console.error('Unable to load networks', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || !defaultLanguageId) {
      return
    }

    setLoading(dispatch, true)
    queryNetworks({
      variables: { defaultLanguageId },
    })
  }, [isAuthenticated, queryNetworks, defaultLanguageId])

  const getCurrentNetwork = useCallback(() => {
    if (!state.currentNetwork) {
      return DEFAULT_KEY_FOR_CONTEXT
    }

    return state.networks.find(network => network.id === state.currentNetwork)
  }, [state.currentNetwork, state.networks])

  const fetchNetworks = useCallback(() => {
    setLoading(dispatch, true)
    refetch()
  }, [refetch])

  const setSelectorConfig = useCallback(config => {
    dispatch({
      type: 'set_selector_config',
      payload: config,
    })
  }, [])

  const validate = useCallback(
    value => {
      return (
        checkAdditionalFlag(value) ||
        state.networks.some(network => network.id === value)
      )
    },
    [state.networks],
  )

  const getDefault = useCallback(() => {
    return state.networks[0]?.id || DEFAULT_KEY_FOR_CONTEXT
  }, [state.networks])

  return (
    <NetworkContext.Provider
      value={{
        state,
        loading: state.loading,
        setCurrentNetwork,
        getCurrentNetwork,
        fetchNetworks,
        setSelectorConfig,
        validate,
        getDefault,
      }}>
      {children}
    </NetworkContext.Provider>
  )
}

export default NetworkProvider

function setNetworks(dispatch, networks) {
  dispatch({
    type: 'set_networks',
    payload: networks,
  })
}

function setLoading(dispatch, loading) {
  dispatch({
    type: 'set_loading',
    payload: loading,
  })
}

export function getSelectedValue() {
  return StorageHelper.getItem(NETWORK_USER_SELECT_STORE_KEY)
}

export function setSelectedValue(value) {
  return StorageHelper.setItem(NETWORK_USER_SELECT_STORE_KEY, value)
}
