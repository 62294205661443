import {
  BellOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { useNotificationContext } from '@contexts/notifications'
import { Avatar, Badge, List, Popover } from 'antd'
import React from 'react'

import styles from './style.module.scss'

const NotificationsMenu = () => {
  const {
    state: { notifications },
    removeNotification,
  } = useNotificationContext()

  return (
    <Popover
      overlayClassName={styles.notificationOverlayWrapper}
      title={<h3 className="popoverHeader">Notifications</h3>}
      placement="bottomRight"
      content={
        <div className={styles.popoverContent}>
          <List
            dataSource={notifications || []}
            renderItem={item => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  e.cancelBubble = true
                  e.stopPropagation()
                  e.preventDefault()

                  item?.onClick()
                }}>
                <List.Item
                  actions={[
                    <CloseOutlined
                      onClick={e => {
                        e.cancelBubble = true
                        e.stopPropagation()
                        e.preventDefault()
                        removeNotification(item?.id)
                      }}
                      style={{ fontSize: '14px' }}
                    />,
                  ]}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        icon={
                          item?.type === 'info' && (
                            <InfoCircleOutlined style={{ color: '#1890ff' }} />
                          )
                        }
                        style={{ backgroundColor: '#fff' }}
                      />
                    }
                    title={item?.message}
                  />
                </List.Item>
              </div>
            )}
          />
        </div>
      }>
      <div className={styles.hoverable}>
        <Badge count={notifications?.length ?? 0} size="small" offset={[-6, 6]}>
          <Avatar icon={<BellOutlined />} className={styles.menuIcon} />
        </Badge>
      </div>
    </Popover>
  )
}

export default NotificationsMenu
