import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { createUploadLink } from 'apollo-upload-client'
import React, { useMemo } from 'react'

import introspectionResult from '../../generated/fragment-matcher'

const ApolloProviderConfigured = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()

  const client = useMemo(() => {
    const link = new createUploadLink({
      uri:
        (process.env.REACT_APP_API_ENDPOINT || 'https://api.wdnsolutions.com') +
        (process.env.REACT_APP_API_PATH || '/api/core'),
      headers: {
        'Cache-Control': 'no-cache',
      },
    })

    const authMiddleware = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently()

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    const cache = new InMemoryCache({
      resultCaching: false,
      possibleTypes: introspectionResult.possibleTypes,
    })

    const apolloConfig = {
      link: from([authMiddleware, link]),
      cache,
      connectToDevTools: false,
    }

    if (process.env.REACT_APP_APOLLO_DEVTOOLS) {
      apolloConfig.connectToDevTools = true
    }

    return new ApolloClient(apolloConfig)
  }, [getAccessTokenSilently])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderConfigured
