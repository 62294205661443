export const IS_NEW_PROPERTY_KEY = 'is-new-import-from-provider-portal'
export const PUBLISHED_KEY = 'published'
export const PARENT_KEY = 'parent'
export const NAME_KEY = 'name'
export const TITLE_KEY = 'title'
export const PLACEHOLDER_KEY = 'placeholder'
export const CREATED_AT_KEY = 'createdAt'
export const SUSPENDED_KEY = 'suspended'
export const FEATURED_IMAGE_KEY = 'featured-image'
export const LOGOTYPE_KEY = 'logotype'
export const RESTRICTED_COUNTRIES_KEY = 'restricted-countries'
export const HIDE_COUNTRIES_KEY = 'hide-countries'
export const PUBLISHED_COUNTRIES_KEY = 'published-countries'
export const META_DESCRIPTION_KEY = 'meta-description'
export const HTML_TITLE_KEY = 'html-title'
export const OG_IMAGE_KEY = 'og-image'
export const OG_TITLE_KEY = 'og-title'
export const OG_DESCRIPTION_KEY = 'og-description'
export const TWITTER_TITLE_KEY = 'twitter-title'
export const TWITTER_DESCRIPTION_KEY = 'twitter-description'
export const TWITTER_IMAGE_KEY = 'twitter-image'
export const SECTION_KEY = 'section'
export const TAGS_KEY = 'tags'
export const BACKGROUND_COLOR_KEY = 'background-color'
export const EMOJI_KEY = 'emoji'
export const SLUG_KEY = 'slug'
export const LABEL_KEY = 'label'
export const ICON_KEY = 'icon'
export const DATA_URL_KEY = 'data-url'
export const PATH_KEY = 'path'
export const PROFILE_PICTURE_KEY = 'profile-picture'
export const SELECTOR_KEY = 'selector'
export const FIELD_KEY = 'field'
export const TEMPLATE_DEFINITION_KEY = 'template-definition'
export const DESCRIPTION_KEY = 'description'
export const SHORT_TITLE_KEY = 'short-title'
export const SHOULD_BE_APPROVED_KEY = 'shouldBeApproved'
export const VERTICAL_KEY = 'vertical'
