import StorageHelper from '@modules/storage'
import { useEffect } from 'react'

export const DEFAULT_KEY_FOR_CONTEXT = null
const ADDITIONAL_FLAGS_PAIRS = [[DEFAULT_KEY_FOR_CONTEXT, 'all']] as const
const ADDITIONAL_FLAGS = new Map(ADDITIONAL_FLAGS_PAIRS)

export function usePersistSelector(storageKey, keyToStore) {
  useEffect(() => {
    let valueToStore = keyToStore
    if (checkAdditionalFlag(keyToStore)) {
      valueToStore = getAdditionalFlagValue(keyToStore)
    }
    StorageHelper.setItem(storageKey, valueToStore)
  }, [storageKey, keyToStore])
}

export function checkAdditionalFlag(key) {
  return ADDITIONAL_FLAGS.has(key)
}

export function getAdditionalFlagValue(key) {
  return ADDITIONAL_FLAGS.get(key)
}

export function getPersistedSelector(storageKey) {
  const valueFromStore = StorageHelper.getItem(storageKey)

  const key = getKeyForValue(valueFromStore)

  if (key !== undefined) {
    return key
  }

  return valueFromStore || DEFAULT_KEY_FOR_CONTEXT
}

export function getKeyForValue(value) {
  return ADDITIONAL_FLAGS_PAIRS.find(pair => pair[1] === value)?.[0]
}
