import gql from 'graphql-tag';
export const PropertyItemFragment = gql`
    fragment PropertyItemFragment on PropertyItem {
  id
  type
  key
  data
  list
  contextId
  updatedAt
  createdAt
  resolvedData {
    __typename
    ... on MediaItem {
      id
      name
      url
    }
  }
}
    `;
export const LanguageFragment = gql`
    fragment LanguageFragment on Language {
  id
  languageCode
  languageTag
  countryCode
  name
  shortName
  shortNameEng
}
    `;
export const StringItemFragment = gql`
    fragment StringItemFragment on StringItem {
  id
  variant
  language {
    ...LanguageFragment
  }
  contextId
  list
  data
  status
  updatedAt
}
    ${LanguageFragment}`;
export const ContentItemFragment = gql`
    fragment ContentItemFragment on ContentItem {
  id
  key
  translationType
  field {
    id
    key
    context
    type
  }
  strings(where: {status: {in: [APPROVED, DRAFT]}}, orderBy: {status: asc}) {
    ...StringItemFragment
  }
  network {
    id
  }
}
    ${StringItemFragment}`;
export const AuthorFragment = gql`
    fragment AuthorFragment on Author {
  id
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const CountryFragment = gql`
    fragment CountryFragment on Country {
  id
  name
  countryCode
}
    `;
export const FilterTaxonomyItemFragment = gql`
    fragment FilterTaxonomyItemFragment on FilterTaxonomyItem {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const FilterTaxonomyRuleFragment = gql`
    fragment FilterTaxonomyRuleFragment on FilterTaxonomyRule {
  id
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  network {
    id
  }
  taxonomyBase {
    id
    content {
      ...ContentItemFragment
    }
  }
  taxonomyFilter {
    id
    content {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const UserFragment = gql`
    fragment UserFragment on User {
  user_id
  email
  name
  picture
  permissions
}
    `;
export const MediaItemFragment = gql`
    fragment MediaItemFragment on MediaItem {
  id
  checksum
  metadata
  mimetype
  url
  name
  createdAt
  createdBy {
    ...UserFragment
  }
  network {
    id
  }
  tags {
    id
    name
  }
}
    ${UserFragment}`;
export const SiteFragment = gql`
    fragment SiteFragment on Site {
  id
  url
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  vertical {
    id
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  language {
    ...LanguageFragment
  }
  parent {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}
${LanguageFragment}`;
export const VerticalFragment = gql`
    fragment VerticalFragment on Vertical {
  id
  network {
    id
  }
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  createdAt
  sites {
    ...SiteFragment
  }
  content {
    ...ContentItemFragment
  }
  properties {
    ...PropertyItemFragment
  }
}
    ${SiteFragment}
${ContentItemFragment}
${PropertyItemFragment}`;
export const MenuItemFragment = gql`
    fragment MenuItemFragment on MenuItem {
  id
  type
  index
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  children {
    id
    type
    properties {
      ...PropertyItemFragment
    }
    content {
      ...ContentItemFragment
    }
  }
  parent {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const MenuFragment = gql`
    fragment MenuFragment on Menu {
  id
  key
  vertical {
    ...VerticalFragment
  }
  items {
    ...MenuItemFragment
  }
}
    ${VerticalFragment}
${MenuItemFragment}`;
export const NetworkFragment = gql`
    fragment NetworkFragment on Network {
  id
  createdAt
  updatedAt
  createdBy {
    ...UserFragment
  }
  verticals {
    ...VerticalFragment
  }
  content {
    ...ContentItemFragment
  }
  properties {
    ...PropertyItemFragment
  }
}
    ${UserFragment}
${VerticalFragment}
${ContentItemFragment}
${PropertyItemFragment}`;
export const PostFragment = gql`
    fragment PostFragment on Post {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  vertical {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const ProductFragment = gql`
    fragment ProductFragment on Product {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const ProviderFragment = gql`
    fragment ProviderFragment on Provider {
  id
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const StringTemplateFragment = gql`
    fragment StringTemplateFragment on StringTemplate {
  id
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  key
  level
  content {
    ...ContentItemFragment
  }
  properties {
    ...PropertyItemFragment
  }
  network {
    id
  }
}
    ${ContentItemFragment}
${PropertyItemFragment}`;
export const TaxonomyItemFragment = gql`
    fragment TaxonomyItemFragment on TaxonomyItem {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  parent {
    id
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    ...ContentItemFragment
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const TemplateFieldFragment = gql`
    fragment TemplateFieldFragment on TemplateField {
  id
  isDefaultField
  template {
    id
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    type
    subtype
    groups {
      id
      name
    }
  }
  key
  type
  list
  data
  generatePages
  group
  required
  hidden
  resourceType
  displayType
  content {
    ...ContentItemFragment
  }
}
    ${ContentItemFragment}`;
export const PublisherFilterTaxonomyItemFragment = gql`
    fragment PublisherFilterTaxonomyItemFragment on FilterTaxonomyItem {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    id
    key
    translationType
    field {
      id
      key
      context
      type
    }
    strings(
      where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
      orderBy: {status: asc}
    ) {
      ...StringItemFragment
    }
    network {
      id
    }
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const MetaFilterTaxonomyRuleNewFragment = gql`
    fragment MetaFilterTaxonomyRuleNewFragment on FilterTaxonomyRule {
  id
  taxonomyBase {
    id
  }
  taxonomyFilter {
    id
    content {
      id
      key
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        id
        data
        contextId
        status
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const PublisherPostFragment = gql`
    fragment PublisherPostFragment on Post {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    id
    key
    translationType
    field {
      id
      key
      context
      type
    }
    strings(
      where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
      orderBy: {status: asc}
    ) {
      ...StringItemFragment
    }
  }
  vertical {
    id
  }
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const PublisherProductFragment = gql`
    fragment PublisherProductFragment on Product {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    id
    key
    translationType
    field {
      id
      key
      context
      type
    }
    strings(
      where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
      orderBy: {status: asc}
    ) {
      ...StringItemFragment
    }
    network {
      id
    }
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const PublisherProviderFragment = gql`
    fragment PublisherProviderFragment on Provider {
  id
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    id
    key
    translationType
    field {
      id
      key
      context
      type
    }
    strings(
      where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
      orderBy: {status: asc}
    ) {
      ...StringItemFragment
    }
    network {
      id
    }
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const PublisherTaxonomyItemFragment = gql`
    fragment PublisherTaxonomyItemFragment on TaxonomyItem {
  id
  type
  createdAt
  updatedAt
  createdBy {
    user_id
    name
  }
  parent {
    id
  }
  properties {
    ...PropertyItemFragment
  }
  content {
    id
    key
    translationType
    field {
      id
      key
      context
      type
    }
    strings(
      where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
      orderBy: {status: asc}
    ) {
      ...StringItemFragment
    }
    network {
      id
    }
  }
  network {
    id
  }
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const AllTaxonomyItemsInput = gql`
    query AllTaxonomyItemsInput($networkId: String, $defaultLanguageId: String!) {
  taxonomyItems(where: {AND: [{parent: null}, {networkId: {equals: $networkId}}]}) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
    children {
      id
      content(where: {key: {equals: "name"}}) {
        id
        key
        strings(
          where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
          orderBy: {status: asc}
          take: 1
        ) {
          id
          data
          language {
            id
            languageTag
          }
        }
      }
    }
  }
}
    `;
export const AllTaxonomyItemsForNetwork = gql`
    query AllTaxonomyItemsForNetwork($languageId: String!, $where: TaxonomyItemWhereInput) {
  taxonomyItems(where: $where) {
    id
    parent {
      id
    }
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $languageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
    properties(where: {key: {equals: "published"}}) {
      key
      data
    }
  }
}
    `;
export const FilteredTaxonomyItemsInput = gql`
    query FilteredTaxonomyItemsInput($where: TaxonomyItemWhereInput!, $defaultLanguageId: String!) {
  taxonomyItems(where: $where) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
    parent {
      id
    }
  }
}
    `;
export const TaxonomyParentItemsInput = gql`
    query TaxonomyParentItemsInput($where: TaxonomyItemWhereInput!, $defaultLanguageId: String!) {
  taxonomyItems(where: $where) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const LinkModalProducts = gql`
    query LinkModalProducts($networkId: String, $defaultLanguageId: String!) {
  resources: products(where: {networkId: {equals: $networkId}}) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
      }
    }
  }
}
    `;
export const LinkModalProviders = gql`
    query LinkModalProviders($networkId: String, $defaultLanguageId: String!) {
  resources: providers(where: {networkId: {equals: $networkId}}) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
      }
    }
  }
}
    `;
export const LinkModalTaxonomyItems = gql`
    query LinkModalTaxonomyItems($networkId: String, $defaultLanguageId: String!) {
  resources: taxonomyItems(where: {networkId: {equals: $networkId}}) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
      }
    }
    parent {
      id
    }
  }
}
    `;
export const NetworksVerticalsSites = gql`
    query NetworksVerticalsSites($defaultLanguageId: String!) {
  networks {
    id
    verticals {
      id
      content(where: {key: {equals: "name"}}) {
        id
        key
        strings(
          where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
          orderBy: {status: asc}
          take: 1
        ) {
          id
          data
          language {
            id
            languageTag
          }
        }
      }
      sites {
        id
        url
      }
    }
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
  }
  countries {
    id
    name
    countryCode
  }
}
    `;
export const NetworkContextSlimNetworks = gql`
    query NetworkContextSlimNetworks($where: NetworkWhereInput, $take: Int, $skip: Int, $defaultLanguageId: String!) {
  networks(where: $where, take: $take, skip: $skip) {
    id
    verticals {
      id
      content(where: {key: {equals: "name"}}) {
        id
        key
        strings(
          where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
          orderBy: {status: asc}
          take: 1
        ) {
          id
          data
          language {
            id
            languageTag
          }
        }
      }
    }
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const ProvidersAndTaxonomiesCounts = gql`
    query ProvidersAndTaxonomiesCounts($providerWhere: ProviderWhereInput, $taxonomyItemWhere: TaxonomyItemWhereInput) {
  providersCount: providersSearchCount(where: $providerWhere)
  taxonomyItemsCount: taxonomyItemsSearchCount(where: $taxonomyItemWhere)
}
    `;
export const CreateDeployJobs = gql`
    mutation CreateDeployJobs($sites: [CreateDeployJobSite!]!) {
  createDeployJobs(sites: $sites) {
    id
    name
  }
}
    `;
export const DeleteContentItem = gql`
    mutation DeleteContentItem($where: ContentItemWhereUniqueInput!) {
  deleteContentItem(where: $where) {
    id
  }
}
    `;
export const DeleteFile = gql`
    mutation DeleteFile($where: MediaItemWhereUniqueInput) {
  deleteFile(where: $where) {
    id
  }
}
    `;
export const DeleteMenu = gql`
    mutation DeleteMenu($where: MenuWhereUniqueInput!) {
  deleteMenu(where: $where) {
    id
  }
}
    `;
export const ImportCsv = gql`
    mutation ImportCsv($mapping: JSON!, $template: JSON!, $data: String!, $config: ImportConfig) {
  importCsv(mapping: $mapping, template: $template, data: $data, config: $config) {
    ok
    id
  }
}
    `;
export const UpdateUser = gql`
    mutation UpdateUser($id: String!, $name: String, $roles: [String!], $permissions: [String!]) {
  updateUser(id: $id, name: $name, roles: $roles, permissions: $permissions) {
    ...UserFragment
  }
}
    ${UserFragment}`;
export const UploadFile = gql`
    mutation UploadFile($file: Upload!, $networkId: String!) {
  uploadFile(file: $file, networkId: $networkId) {
    ...MediaItemFragment
  }
}
    ${MediaItemFragment}`;
export const UploadFileWithCloudinaryWidget = gql`
    mutation UploadFileWithCloudinaryWidget($networkId: String!, $cloudinaryFileObject: CloudinaryFileInput!) {
  uploadFileWithCloudinaryWidget(
    networkId: $networkId
    cloudinaryFileObject: $cloudinaryFileObject
  ) {
    ...MediaItemFragment
  }
}
    ${MediaItemFragment}`;
export const UploadFileWithUrl = gql`
    mutation UploadFileWithURL($url: String!, $networkId: String!) {
  uploadFileWithURL(url: $url, networkId: $networkId) {
    ...MediaItemFragment
  }
}
    ${MediaItemFragment}`;
export const UploadImage = gql`
    mutation UploadImage($file: Upload!, $networkId: String!) {
  uploadFile(file: $file, networkId: $networkId) {
    url
  }
}
    `;
export const CreateTranslationJob = gql`
    mutation CreateTranslationJob($jobs: [TranslationJobData!]!) {
  createTranslationJobs(jobs: $jobs) {
    id
    data
    timestamp
  }
}
    `;
export const UpsertFilterTaxonomyItem = gql`
    mutation UpsertFilterTaxonomyItem($where: FilterTaxonomyItemWhereUniqueInput!, $data: FilterTaxonomyItemUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertFilterTaxonomyItem(where: $where, data: $data, current: $current) {
    ...FilterTaxonomyItemFragment
  }
}
    ${FilterTaxonomyItemFragment}`;
export const UpsertFilterTaxonomyRule = gql`
    mutation UpsertFilterTaxonomyRule($where: FilterTaxonomyRuleWhereUniqueInput!, $data: FilterTaxonomyRuleUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertFilterTaxonomyRule(where: $where, data: $data, current: $current) {
    ...FilterTaxonomyRuleFragment
  }
}
    ${FilterTaxonomyRuleFragment}`;
export const UpsertPost = gql`
    mutation UpsertPost($where: PostWhereUniqueInput!, $data: PostUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertPost(where: $where, data: $data, current: $current) {
    ...PostFragment
  }
}
    ${PostFragment}`;
export const UpsertProduct = gql`
    mutation UpsertProduct($where: ProductWhereUniqueInput!, $data: ProductUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertProduct(where: $where, data: $data, current: $current) {
    ...ProductFragment
  }
}
    ${ProductFragment}`;
export const UpsertProvider = gql`
    mutation UpsertProvider($where: ProviderWhereUniqueInput!, $data: ProviderUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertProvider(where: $where, data: $data, current: $current) {
    id
  }
}
    `;
export const UpsertStringTemplate = gql`
    mutation UpsertStringTemplate($where: StringTemplateWhereUniqueInput!, $data: StringTemplateUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertStringTemplate(where: $where, data: $data, current: $current) {
    id
  }
}
    `;
export const UpsertTaxonomyItem = gql`
    mutation UpsertTaxonomyItem($where: TaxonomyItemWhereUniqueInput!, $data: TaxonomyItemUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertTaxonomyItem(where: $where, data: $data, current: $current) {
    ...TaxonomyItemFragment
  }
}
    ${TaxonomyItemFragment}`;
export const ExportCsv = gql`
    query ExportCsv($template: JSON!, $data: JSON!, $config: ExportConfig) {
  exportCsv(template: $template, data: $data, config: $config) {
    ok
    result
    error
  }
}
    `;
export const Authors = gql`
    query Authors($where: AuthorWhereInput, $take: Int, $skip: Int) {
  authors(where: $where, take: $take, skip: $skip) {
    id
    properties {
      id
      key
      data
    }
  }
  count: authorCount(where: $where)
}
    `;
export const SiteContextSlimSites = gql`
    query SiteContextSlimSites($where: SiteWhereInput, $take: Int, $skip: Int) {
  sites(where: $where, take: $take, skip: $skip) {
    id
    url
    properties(where: {key: {equals: "name"}}) {
      id
      key
      data
    }
    language {
      id
      languageCode
      languageTag
      countryCode
    }
  }
  count: siteCount(where: $where)
}
    `;
export const Countries = gql`
    query Countries($where: CountryWhereInput) {
  countries(where: $where) {
    ...CountryFragment
  }
}
    ${CountryFragment}`;
export const HistoryForResource = gql`
    query HistoryForResource($id: ID!, $resourceType: HistoryResourceType!, $take: Int, $skip: Int) {
  historyForResource(
    id: $id
    resourceType: $resourceType
    take: $take
    skip: $skip
  ) {
    date
    changedKeys {
      content
      properties
    }
    user {
      name
    }
    data
    previousData
  }
}
    `;
export const DynamicFormInputPropertyResource = gql`
    query DynamicFormInputPropertyResource($languageId: String!, $networkId: String!) {
  providers(where: {networkId: {equals: $networkId}}) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $languageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
    properties(where: {key: {equals: "published"}}) {
      id
      key
      data
    }
  }
  taxonomyItems(where: {networkId: {equals: $networkId}}) {
    id
    parent {
      id
    }
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $languageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          languageTag
        }
      }
    }
    properties(where: {key: {equals: "published"}}) {
      id
      key
      data
    }
  }
}
    `;
export const FilterTaxonomyRule = gql`
    query FilterTaxonomyRule($where: FilterTaxonomyRuleWhereUniqueInput!) {
  filterTaxonomyRule(where: $where) {
    ...FilterTaxonomyRuleFragment
  }
}
    ${FilterTaxonomyRuleFragment}`;
export const FilterTaxonomyRules = gql`
    query FilterTaxonomyRules($where: FilterTaxonomyRuleWhereInput, $take: Int, $skip: Int) {
  filterTaxonomyRules(where: $where, take: $take, skip: $skip) {
    ...FilterTaxonomyRuleFragment
  }
  count: filterTaxonomyRuleCount(where: $where)
}
    ${FilterTaxonomyRuleFragment}`;
export const Languages = gql`
    query Languages($where: LanguageWhereInput) {
  languages(where: $where) {
    ...LanguageFragment
  }
}
    ${LanguageFragment}`;
export const Me = gql`
    query Me {
  me {
    ...UserFragment
  }
}
    ${UserFragment}`;
export const MediaItem = gql`
    query MediaItem($where: MediaItemWhereUniqueInput!) {
  mediaItem(where: $where) {
    ...MediaItemFragment
  }
}
    ${MediaItemFragment}`;
export const MediaItems = gql`
    query MediaItems($where: MediaItemWhereInput, $take: Int, $skip: Int, $orderBy: [MediaItemOrderByWithRelationInput!]) {
  mediaItems(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    ...MediaItemFragment
  }
  count: mediaItemCount(where: $where)
}
    ${MediaItemFragment}`;
export const Networks = gql`
    query Networks($where: NetworkWhereInput, $take: Int, $skip: Int) {
  networks(where: $where, take: $take, skip: $skip) {
    ...NetworkFragment
  }
  count: networkCount(where: $where)
}
    ${NetworkFragment}`;
export const Posts = gql`
    query Posts($where: PostWhereInput, $take: Int, $skip: Int, $defaultLanguageId: String!) {
  posts(where: $where, take: $take, skip: $skip) {
    id
    content(where: {key: {equals: "title"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        ...StringItemFragment
      }
    }
    properties(where: {key: {equals: "published"}}) {
      id
      key
      data
    }
  }
}
    ${StringItemFragment}`;
export const Products = gql`
    query Products($where: ProductWhereInput, $take: Int, $skip: Int) {
  products(where: $where, take: $take, skip: $skip) {
    ...ProductFragment
  }
  count: productCount(where: $where)
}
    ${ProductFragment}`;
export const Provider = gql`
    query Provider($where: ProviderWhereUniqueInput!, $currentLanguageId: String!) {
  provider(where: $where) {
    id
    content {
      id
      key
      strings(
        where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        id
        language {
          id
          languageTag
        }
        contextId
        data
      }
    }
  }
}
    `;
export const Providers = gql`
    query Providers($where: ProviderWhereInput, $take: Int, $skip: Int) {
  providers(where: $where, take: $take, skip: $skip) {
    ...ProviderFragment
  }
  count: providerCount(where: $where)
}
    ${ProviderFragment}`;
export const RankingProviders = gql`
    query RankingProviders($where: ProviderWhereInput, $take: Int, $skip: Int, $defaultLanguageId: String!) {
  providers(where: $where, take: $take, skip: $skip) {
    id
    properties(
      where: {OR: [{key: {in: ["logotype", "published", "restricted-countries", "suspended"]}}, {type: {equals: TAXONOMYITEM}}]}
    ) {
      id
      key
      data
      type
    }
    content(where: {key: {in: ["name", "bonus-text"]}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const SchedulerPosts = gql`
    query SchedulerPosts($where: PostWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!) {
  posts(where: $where, take: $take, skip: $skip) {
    id
    type
    createdAt
    publishedAt
    properties(where: {key: {in: ["published-at", "published"]}}) {
      id
      key
      data
    }
    content(where: {key: {equals: "title"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        ...StringItemFragment
      }
    }
  }
  count: postCount(where: $where)
}
    ${StringItemFragment}`;
export const Sites = gql`
    query Sites($where: SiteWhereInput, $take: Int, $skip: Int) {
  sites(where: $where, take: $take, skip: $skip) {
    id
    url
    vertical {
      id
    }
  }
  count: siteCount(where: $where)
}
    `;
export const StringTemplates = gql`
    query StringTemplates($where: StringTemplateWhereInput, $take: Int, $skip: Int) {
  stringTemplates(where: $where, take: $take, skip: $skip) {
    ...StringTemplateFragment
  }
  count: stringTemplateCount(where: $where)
}
    ${StringTemplateFragment}`;
export const Tags = gql`
    query Tags($take: Int, $orderBy: [TagOrderByWithRelationInput!], $where: TagWhereInput) {
  tags(take: $take, orderBy: $orderBy, where: $where) {
    name
    id
  }
}
    `;
export const TaxonomyItem = gql`
    query TaxonomyItem($where: TaxonomyItemWhereUniqueInput!, $defaultLanguageId: String!) {
  taxonomyItem(where: $where) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        ...StringItemFragment
      }
    }
  }
}
    ${StringItemFragment}`;
export const TaxonomyItems = gql`
    query TaxonomyItems($where: TaxonomyItemWhereInput, $take: Int, $skip: Int) {
  taxonomyItems(where: $where, take: $take, skip: $skip) {
    ...TaxonomyItemFragment
  }
  count: taxonomyItemCount(where: $where)
}
    ${TaxonomyItemFragment}`;
export const Templates = gql`
    query Templates($where: TemplateWhereInput!) {
  templates(where: $where) {
    id
    createdAt
    updatedAt
    type
    subtype
    content {
      id
      key
      strings {
        ...StringItemFragment
      }
    }
    fields {
      id
      required
      index
      group
      key
      type
      hidden
      list
      data
      generatePages
      context
      resourceType
      isDefaultField
      defaultTranslationType
      displayType
      roles
      content {
        id
        key
        strings {
          ...StringItemFragment
        }
      }
    }
    groups {
      id
      name
      index
      extended
    }
    network {
      id
    }
  }
}
    ${StringItemFragment}`;
export const TranslationJobsEstimatedCost = gql`
    query TranslationJobsEstimatedCost($jobs: [TranslationJobData!]!) {
  translationJobsEstimatedCost(jobs: $jobs)
}
    `;
export const User = gql`
    query User($id: String!) {
  user(id: $id) {
    app_metadata
    ...UserFragment
  }
}
    ${UserFragment}`;
export const Users = gql`
    query Users {
  users {
    app_metadata
    ...UserFragment
  }
}
    ${UserFragment}`;
export const Verticals = gql`
    query Verticals($where: VerticalWhereInput, $take: Int, $skip: Int, $defaultLanguageId: String!) {
  verticals(where: $where, take: $take, skip: $skip) {
    id
    createdAt
    sites {
      id
      url
      language {
        id
        languageTag
        countryCode
      }
    }
    content {
      id
      key
      strings(
        where: {status: {in: [APPROVED, DRAFT]}, languageId: {equals: $defaultLanguageId}}
        orderBy: {status: asc}
        take: 1
      ) {
        ...StringItemFragment
      }
    }
  }
  count: verticalCount(where: $where)
}
    ${StringItemFragment}`;
export const DeleteAuthor = gql`
    mutation DeleteAuthor($where: AuthorWhereUniqueInput!) {
  deleteAuthor(where: $where) {
    id
  }
}
    `;
export const Author = gql`
    query Author($where: AuthorWhereUniqueInput!) {
  author(where: $where) {
    ...AuthorFragment
  }
}
    ${AuthorFragment}`;
export const DynamicListAuthors = gql`
    query DynamicListAuthors($where: AuthorWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [AuthorOrderByWithRelationInput!]) {
  count: authorsSearchCount(where: $where, search: $search)
  authors: authorsSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const UpsertAuthor = gql`
    mutation UpsertAuthor($where: AuthorWhereUniqueInput!, $data: AuthorUpsertDataInput!, $current: ResourceCurrentInput!) {
  upsertAuthor(where: $where, data: $data, current: $current) {
    ...AuthorFragment
  }
}
    ${AuthorFragment}`;
export const ComplianceRules = gql`
    query ComplianceRules($networkId: String!) {
  complianceRules: propertyItems(
    where: {key: {equals: "compliance-rules"}, Network: {id: {equals: $networkId}}}
  ) {
    ...PropertyItemFragment
  }
}
    ${PropertyItemFragment}`;
export const UpdatePropertyItem = gql`
    mutation UpdatePropertyItem($where: PropertyItemWhereUniqueInput!, $data: PropertyItemUpdateInput!) {
  updatePropertyItem(where: $where, data: $data) {
    ...PropertyItemFragment
  }
}
    ${PropertyItemFragment}`;
export const DeleteFilterTaxonomyItem = gql`
    mutation DeleteFilterTaxonomyItem($where: FilterTaxonomyItemWhereUniqueInput!) {
  deleteFilterTaxonomyItem(where: $where) {
    id
  }
}
    `;
export const DynamicListFilterTaxonomyItems = gql`
    query DynamicListFilterTaxonomyItems($where: FilterTaxonomyItemWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [FilterTaxonomyItemOrderByWithRelationInput!]) {
  count: filterTaxonomyItemsSearchCount(where: $where, search: $search)
  filterTaxonomyItems: filterTaxonomyItemsSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    type
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const PublisherFilterTaxonomyItem = gql`
    query PublisherFilterTaxonomyItem($where: FilterTaxonomyItemWhereUniqueInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  filterTaxonomyItem(where: $where) {
    ...PublisherFilterTaxonomyItemFragment
  }
}
    ${PublisherFilterTaxonomyItemFragment}`;
export const PublisherUpsertFilterTaxonomyItem = gql`
    mutation PublisherUpsertFilterTaxonomyItem($where: FilterTaxonomyItemWhereUniqueInput!, $data: FilterTaxonomyItemUpsertDataInput!, $current: ResourceCurrentInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  upsertFilterTaxonomyItem(where: $where, data: $data, current: $current) {
    ...PublisherFilterTaxonomyItemFragment
  }
}
    ${PublisherFilterTaxonomyItemFragment}`;
export const DeleteFilterTaxonomyRule = gql`
    mutation DeleteFilterTaxonomyRule($where: FilterTaxonomyRuleWhereUniqueInput!) {
  deleteFilterTaxonomyRule(where: $where) {
    id
  }
}
    `;
export const DynamicListFilterTaxonomyRules = gql`
    query DynamicListFilterTaxonomyRules($where: FilterTaxonomyRuleWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [FilterTaxonomyRuleOrderByWithRelationInput!]) {
  count: filterTaxonomyRulesSearchCount(where: $where, search: $search)
  filterTaxonomyRules: filterTaxonomyRulesSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
    taxonomyBase {
      id
      content(where: {key: {in: $contentKeys}}) {
        ...ContentItemFragment
      }
    }
    taxonomyFilter {
      id
      content(where: {key: {in: $contentKeys}}) {
        ...ContentItemFragment
      }
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const CreateOrUpdateMenu = gql`
    mutation CreateOrUpdateMenu($menu: MenuInput!, $menuItemsDelete: [String]) {
  upsertMenuCustom(menu: $menu, menuItemsDelete: $menuItemsDelete) {
    id
  }
}
    `;
export const DeleteMenus = gql`
    mutation DeleteMenus($where: MenuWhereInput!) {
  deleteMenus(where: $where) {
    count
  }
}
    `;
export const LoadMenus = gql`
    query LoadMenus($where: MenuWhereInput, $currentLanguageId: String!) {
  menus(where: $where) {
    id
    key
    items {
      id
      type
      index
      properties {
        id
        key
        type
        data
      }
      content {
        id
        key
        strings(
          where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
          orderBy: {status: asc}
          take: 1
        ) {
          id
          data
          language {
            id
            languageTag
          }
        }
      }
      parent {
        id
      }
    }
  }
}
    `;
export const LoadMinifiedMenusWithSites = gql`
    query LoadMinifiedMenusWithSites($where: MenuWhereInput) {
  menus(where: $where) {
    id
    key
    site {
      id
      url
      properties(where: {key: {equals: "name"}}) {
        id
        key
        data
      }
      language {
        id
        countryCode
        languageTag
      }
    }
  }
}
    `;
export const MenuPosts = gql`
    query MenuPosts($where: PostWhereInput, $take: Int, $skip: Int, $defaultLanguageId: String!) {
  posts(where: $where, take: $take, skip: $skip) {
    id
    content(where: {key: {equals: "title"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        language {
          id
          languageTag
        }
        data
      }
    }
    properties(where: {key: {equals: "published"}}) {
      id
      key
      data
    }
  }
  count: postCount(where: $where)
}
    `;
export const MenuProviders = gql`
    query MenuProviders($where: ProviderWhereInput, $take: Int, $skip: Int, $defaultLanguageId: String!) {
  providers(where: $where, take: $take, skip: $skip) {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        language {
          id
          languageTag
        }
        data
      }
    }
    properties(where: {key: {equals: "published"}}) {
      id
      key
      data
    }
  }
}
    `;
export const MetaStringTemplateVariables = gql`
    query MetaStringTemplateVariables($where: StringTemplateVariableWhereInput) {
  stringTemplateVariables(where: $where) {
    id
    type
    key
    name
    group
    data
  }
}
    `;
export const MetaProduct = gql`
    query MetaProduct($where: ProductWhereUniqueInput!, $currentLanguageId: String!) {
  product(where: $where) {
    id
    type
    content(
      where: {OR: [{field: {displayType: {in: [META, UI]}}}, {field: {key: {equals: "name"}}}]}
    ) {
      id
      key
      translationType
      field {
        id
        key
        context
        type
      }
      strings(
        where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        id
        data
        contextId
        status
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const MetaProducts = gql`
    query MetaProducts($where: ProductWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!, $defaultLanguageId: String!) {
  products(where: $where, take: $take, skip: $skip) {
    id
    type
    content(where: {key: {in: ["name", "title"]}}) {
      id
      key
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED]}}
        orderBy: {status: asc}
      ) {
        id
        data
        status
        contextId
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const MetaProvider = gql`
    query MetaProvider($where: ProviderWhereUniqueInput!, $currentLanguageId: String!) {
  provider(where: $where) {
    id
    content(
      where: {OR: [{field: {displayType: {in: [META, UI]}}}, {field: {key: {equals: "name"}}}]}
    ) {
      id
      key
      translationType
      field {
        id
        key
        context
        type
      }
      strings(
        where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        id
        data
        contextId
        status
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const MetaProviders = gql`
    query MetaProviders($where: ProviderWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!, $defaultLanguageId: String!) {
  providers(where: $where, take: $take, skip: $skip) {
    id
    content(where: {key: {in: ["name", "title"]}}) {
      id
      key
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED]}}
        orderBy: {status: asc}
      ) {
        id
        data
        status
        contextId
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const MetaFilterTaxonomyRules = gql`
    query MetaFilterTaxonomyRules($where: FilterTaxonomyRuleWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!, $defaultLanguageId: String!) {
  filterTaxonomyRules(where: $where, take: $take, skip: $skip) {
    ...MetaFilterTaxonomyRuleNewFragment
  }
  count: filterTaxonomyRuleCount(where: $where)
}
    ${MetaFilterTaxonomyRuleNewFragment}`;
export const MetaTaxonomyItem = gql`
    query MetaTaxonomyItem($where: TaxonomyItemWhereUniqueInput!, $currentLanguageId: String!) {
  taxonomyItem(where: $where) {
    id
    parent {
      id
      content(
        where: {OR: [{field: {displayType: {in: [META, UI]}}}, {field: {key: {equals: "name"}}}]}
      ) {
        id
        key
        translationType
        field {
          id
          key
          context
          type
        }
        strings(
          where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
          orderBy: {status: asc}
        ) {
          id
          data
          contextId
          status
          language {
            id
            languageTag
          }
        }
      }
    }
    content(where: {field: {displayType: {in: [META, UI]}}}) {
      id
      key
      translationType
      field {
        id
        key
        context
        type
      }
      strings(
        where: {languageId: {equals: $currentLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        id
        data
        contextId
        status
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const MetaTaxonomyItems = gql`
    query MetaTaxonomyItems($where: TaxonomyItemWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!, $defaultLanguageId: String!) {
  taxonomyItems(where: $where, take: $take, skip: $skip) {
    id
    parent {
      id
    }
    content(where: {key: {in: ["name", "title"]}}) {
      id
      key
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED]}}
        orderBy: {status: asc}
      ) {
        id
        data
        status
        contextId
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const MetaStringTemplates = gql`
    query MetaStringTemplates($where: StringTemplateWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!, $defaultLanguageId: String!) {
  stringTemplates(where: $where, take: $take, skip: $skip) {
    id
    key
    level
    content {
      id
      key
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {equals: APPROVED}}
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
    properties {
      ...PropertyItemFragment
    }
  }
}
    ${PropertyItemFragment}`;
export const Network = gql`
    query Network($where: NetworkWhereUniqueInput!) {
  network(where: $where) {
    ...NetworkFragment
  }
}
    ${NetworkFragment}`;
export const UpsertNetwork = gql`
    mutation UpsertNetwork($where: NetworkWhereUniqueInput!, $data: NetworkUpsertDataInput!) {
  upsertNetwork(where: $where, data: $data) {
    ...NetworkFragment
  }
}
    ${NetworkFragment}`;
export const DeletePost = gql`
    mutation DeletePost($where: PostWhereUniqueInput!) {
  deletePost(where: $where) {
    id
  }
}
    `;
export const DynamicListPosts = gql`
    query DynamicListPosts($where: PostWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [PostOrderByWithRelationInput!]) {
  count: postsSearchCount(where: $where, search: $search)
  posts: postsSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    type
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const PublisherPost = gql`
    query PublisherPost($where: PostWhereUniqueInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  post(where: $where) {
    ...PublisherPostFragment
  }
}
    ${PublisherPostFragment}`;
export const PublisherUpsertPost = gql`
    mutation PublisherUpsertPost($where: PostWhereUniqueInput!, $data: PostUpsertDataInput!, $current: ResourceCurrentInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  upsertPost(where: $where, data: $data, current: $current) {
    ...PublisherPostFragment
  }
}
    ${PublisherPostFragment}`;
export const DeleteProduct = gql`
    mutation DeleteProduct($where: ProductWhereUniqueInput!) {
  deleteProduct(where: $where) {
    id
  }
}
    `;
export const DynamicListProducts = gql`
    query DynamicListProducts($where: ProductWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [ProductOrderByWithRelationInput!]) {
  count: productsSearchCount(where: $where, search: $search)
  products: productsSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    type
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const PublisherProduct = gql`
    query PublisherProduct($where: ProductWhereUniqueInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  product(where: $where) {
    ...PublisherProductFragment
  }
}
    ${PublisherProductFragment}`;
export const PublisherUpsertProduct = gql`
    mutation PublisherUpsertProduct($where: ProductWhereUniqueInput!, $data: ProductUpsertDataInput!, $current: ResourceCurrentInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  upsertProduct(where: $where, data: $data, current: $current) {
    ...PublisherProductFragment
  }
}
    ${PublisherProductFragment}`;
export const ProviderOverview = gql`
    query ProviderOverview($where: ProviderWhereInput, $take: Int, $skip: Int, $content: ContentItemWhereInput, $defaultLanguageId: String!) {
  providers(where: $where, take: $take, skip: $skip) {
    id
    properties(where: {key: {equals: "logotype"}}) {
      ...PropertyItemFragment
    }
    content(where: $content) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        ...StringItemFragment
      }
    }
  }
  count: providerCount(where: $where)
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const DeleteProvider = gql`
    mutation DeleteProvider($where: ProviderWhereUniqueInput!) {
  deleteProvider(where: $where) {
    id
  }
}
    `;
export const DynamicListProviders = gql`
    query DynamicListProviders($where: ProviderWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [ProviderOrderByWithRelationInput!]) {
  count: providersSearchCount(where: $where, search: $search)
  providers: providersSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    createdAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const PublisherProvider = gql`
    query PublisherProvider($where: ProviderWhereUniqueInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  provider(where: $where) {
    id
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties {
      ...PropertyItemFragment
    }
    content {
      id
      key
      translationType
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        ...StringItemFragment
      }
    }
  }
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const PublisherProviders = gql`
    query PublisherProviders($where: ProviderWhereInput, $take: Int, $skip: Int, $currentLanguageId: String!, $defaultLanguageId: String!) {
  providers(where: $where, take: $take, skip: $skip) {
    id
    createdAt
    updatedAt
    properties {
      ...PropertyItemFragment
    }
    content {
      id
      key
      translationType
      strings(
        where: {languageId: {in: [$currentLanguageId, $defaultLanguageId]}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
      ) {
        ...StringItemFragment
      }
    }
  }
  count: providerCount(where: $where)
}
    ${PropertyItemFragment}
${StringItemFragment}`;
export const PublisherUpsertProvider = gql`
    mutation PublisherUpsertProvider($where: ProviderWhereUniqueInput!, $data: ProviderUpsertDataInput!, $current: ResourceCurrentInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  upsertProvider(where: $where, data: $data, current: $current) {
    ...PublisherProviderFragment
  }
}
    ${PublisherProviderFragment}`;
export const ProvidersStatus = gql`
    query ProvidersStatus($where: ProviderWhereInput, $take: Int, $skip: Int, $content: ContentItemWhereInput, $defaultLanguageId: String!) {
  providers(where: $where, take: $take, skip: $skip) {
    id
    createdAt
    updatedAt
    properties(where: {key: {in: ["published", "affiliate-link"]}}) {
      id
      key
      data
      contextId
    }
    content(where: $content) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        language {
          ...LanguageFragment
        }
        contextId
        data
      }
    }
  }
}
    ${LanguageFragment}`;
export const Logotypes = gql`
    query Logotypes($where: MediaItemWhereInput) {
  logo: mediaItems(where: $where) {
    id
    url
  }
}
    `;
export const RankingPositionNetwork = gql`
    query RankingPositionNetwork($defaultLanguageId: String!) {
  networks {
    id
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        data
        language {
          id
          languageTag
        }
      }
    }
  }
}
    `;
export const DeleteSite = gql`
    mutation DeleteSite($where: SiteWhereUniqueInput!) {
  deleteSite(where: $where) {
    id
  }
}
    `;
export const Site = gql`
    query Site($where: SiteWhereUniqueInput!) {
  site(where: $where) {
    ...SiteFragment
  }
}
    ${SiteFragment}`;
export const DynamicListSites = gql`
    query DynamicListSites($where: SiteWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [SiteOrderByWithRelationInput!]) {
  count: sitesSearchCount(where: $where, search: $search)
  sites: sitesSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    createdAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
    language {
      ...LanguageFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}
${LanguageFragment}`;
export const UpsertSite = gql`
    mutation UpsertSite($where: SiteWhereUniqueInput!, $data: SiteUpsertDataInput!, $current: SiteCurrentInput!) {
  upsertSite(where: $where, data: $data, current: $current) {
    ...SiteFragment
  }
}
    ${SiteFragment}`;
export const SitesStatus = gql`
    query SitesStatus($vertical: String!) {
  sites(where: {verticalId: {equals: $vertical}}, orderBy: {url: asc}) {
    id
    url
    createdAt
    deployedAt
    properties {
      id
      key
      data
    }
    language {
      id
      countryCode
      languageTag
    }
  }
}
    `;
export const NewsTaxStatus = gql`
    query NewsTaxStatus($where: PostWhereInput) {
  posts(where: $where) {
    id
    properties(where: {key: {in: ["tags", "section"]}}) {
      id
      key
      data
    }
    vertical {
      id
    }
  }
}
    `;
export const ProviderTaxStatus = gql`
    query ProviderTaxStatus($where: ProviderWhereInput, $defaultLanguageId: String!) {
  providers(where: $where) {
    id
    properties(where: {key: {contains: "-taxonomies"}}) {
      id
      key
      data
    }
    content(where: {key: {equals: "name"}}) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        id
        language {
          ...LanguageFragment
        }
        data
      }
    }
  }
}
    ${LanguageFragment}`;
export const TaxonomyItemsStatus = gql`
    query TaxonomyItemsStatus($where: TaxonomyItemWhereInput, $take: Int, $skip: Int, $content: ContentItemWhereInput, $defaultLanguageId: String!) {
  taxonomyItems(where: $where, take: $take, skip: $skip) {
    id
    createdAt
    updatedAt
    properties(where: {key: {in: ["published-countries", "published"]}}) {
      id
      key
      data
    }
    content(where: $content) {
      id
      key
      strings(
        where: {languageId: {equals: $defaultLanguageId}, status: {in: [APPROVED, DRAFT]}}
        orderBy: {status: asc}
        take: 1
      ) {
        ...StringItemFragment
      }
    }
  }
}
    ${StringItemFragment}`;
export const UpsertPropertyItem = gql`
    mutation UpsertPropertyItem($where: PropertyItemWhereUniqueInput!, $create: PropertyItemCreateInput!, $update: PropertyItemUpdateInput!) {
  upsertPropertyItem(where: $where, create: $create, update: $update) {
    ...PropertyItemFragment
  }
}
    ${PropertyItemFragment}`;
export const DeleteTaxonomyItem = gql`
    mutation DeleteTaxonomyItem($where: TaxonomyItemWhereUniqueInput!) {
  deleteTaxonomyItem(where: $where) {
    id
  }
}
    `;
export const PublisherTaxonomyItem = gql`
    query PublisherTaxonomyItem($where: TaxonomyItemWhereUniqueInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  taxonomyItem(where: $where) {
    ...PublisherTaxonomyItemFragment
  }
}
    ${PublisherTaxonomyItemFragment}`;
export const DynamicListTaxonomyItems = gql`
    query DynamicListTaxonomyItems($where: TaxonomyItemWhereInput, $search: ResourceSearchWhereInput, $take: Int, $skip: Int, $contentKeys: [String!], $propertyKeys: [String!], $orderBy: [TaxonomyItemOrderByWithRelationInput!]) {
  count: taxonomyItemsSearchCount(where: $where, search: $search)
  taxonomyItems: taxonomyItemsSearch(
    where: $where
    search: $search
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) {
    id
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    properties(where: {key: {in: $propertyKeys}}) {
      ...PropertyItemFragment
    }
    content(where: {key: {in: $contentKeys}}) {
      ...ContentItemFragment
    }
  }
}
    ${PropertyItemFragment}
${ContentItemFragment}`;
export const PublisherUpsertTaxonomyItem = gql`
    mutation PublisherUpsertTaxonomyItem($where: TaxonomyItemWhereUniqueInput!, $data: TaxonomyItemUpsertDataInput!, $current: ResourceCurrentInput!, $currentLanguageId: String!, $defaultLanguageId: String!) {
  upsertTaxonomyItem(where: $where, data: $data, current: $current) {
    ...PublisherTaxonomyItemFragment
  }
}
    ${PublisherTaxonomyItemFragment}`;
export const DeleteVertical = gql`
    mutation DeleteVertical($where: VerticalWhereUniqueInput!) {
  deleteVertical(where: $where) {
    id
  }
}
    `;
export const UpsertVertical = gql`
    mutation UpsertVertical($where: VerticalWhereUniqueInput!, $data: VerticalUpsertDataInput!, $current: VerticalCurrentInput!) {
  upsertVertical(where: $where, data: $data, current: $current) {
    ...VerticalFragment
  }
}
    ${VerticalFragment}`;
export const Vertical = gql`
    query Vertical($where: VerticalWhereUniqueInput!) {
  vertical(where: $where) {
    id
    network {
      id
    }
    createdAt
    updatedAt
    createdBy {
      user_id
      name
    }
    content {
      ...ContentItemFragment
    }
    properties {
      ...PropertyItemFragment
    }
  }
}
    ${ContentItemFragment}
${PropertyItemFragment}`;