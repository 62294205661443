import './style.module.scss'

import LiveUsers from '@components/LayoutComponents/TopBar/LiveUsers'
import NetworkSelect from '@components/LayoutComponents/TopBar/NetworkSelect'
import NotificationsMenu from '@components/LayoutComponents/TopBar/NotificationsMenu'
import ProfileMenu from '@components/LayoutComponents/TopBar/ProfileMenu'
import SiteSelect from '@components/LayoutComponents/TopBar/SiteSelect'
import VerticalSelect from '@components/LayoutComponents/TopBar/VerticalSelect'
import { usePermissions } from '@components/Permissions'
import { APP_MENU_SELECTED_KEYS } from '@constants/storageHelper'
import { useMenuContext } from '@contexts/menu'
import { Menu } from 'antd'
import find from 'lodash/find'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import store from 'store'

import { flattenItems, generateMenuItems } from '../helpers'
import { generateItem } from './helpers'

const MenuTop = () => {
  const {
    state: { menuTopData: menuData },
  } = useMenuContext()

  const [selectedKeys, setSelectedKeys] = useState(
    store.get(APP_MENU_SELECTED_KEYS) || [],
  )

  const { allowed } = usePermissions()

  const location = useLocation()

  useEffect(() => {
    const selectedItem = find(flattenItems(menuData, 'children'), [
      'url',
      location.pathname,
    ])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }, [location.pathname, menuData])

  const handleClick = e => {
    store.set(APP_MENU_SELECTED_KEYS, [e.key])
    setSelectedKeys([e.key])
  }

  const menuChildren = generateMenuItems(allowed, menuData, generateItem)

  return (
    <div style={{ display: 'flex', paddingLeft: '10px' }}>
      <div style={{ padding: '0 5px' }}>
        <NetworkSelect />
      </div>
      <div style={{ padding: '0 5px' }}>
        <VerticalSelect />
      </div>
      <div style={{ padding: '0 5px' }}>
        <SiteSelect />
      </div>
      <div style={{ padding: '0 5px', flex: 'auto' }}>
        <Menu
          theme="dark"
          onClick={handleClick}
          selectedKeys={selectedKeys}
          mode="horizontal"
          items={menuChildren}
        />
      </div>
      <div style={{ flex: 1 }}>{/* free space eater */}</div>
      <div style={{ padding: '0 5px' }}>
        <ProfileMenu />
      </div>
      <div style={{ padding: '0 5px' }}>
        <NotificationsMenu />
      </div>
      <div style={{ padding: '0 5px' }}>
        <LiveUsers />
      </div>
    </div>
  )
}

export default MenuTop
