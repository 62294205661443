import NewTag from '@components/NewTag'
import { useCountsContext } from '@contexts/counts'
import { Badge } from 'antd'
import React, { FC } from 'react'

import styles from '../../style.module.scss'
import { IMenuItemLabelWithCount } from './types'

const MenuItemLabelWithCount: FC<IMenuItemLabelWithCount> = ({
  countName,
  icon,
  title,
  isMenuCollapsed,
}) => {
  const { state } = useCountsContext()

  const currentCount = state[countName]

  if (isMenuCollapsed) {
    return (
      <span>
        <Badge count={currentCount} color="#52C41A" offset={[-7, 10]}>
          <span className={`${icon} ${styles.icon}`} />
          <span className={styles.title}>{title}</span>
        </Badge>
      </span>
    )
  }

  return (
    <span>
      <span className={`${icon} ${styles.icon}`} />
      <span className={styles.title}>{title}</span>
      {currentCount ? (
        <NewTag text={`+${currentCount}`} color="#52C41A" />
      ) : null}
    </span>
  )
}

export default MenuItemLabelWithCount
