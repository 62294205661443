import MenuLeft from '@components/LayoutComponents/Menu/MenuLeft'
import MenuTop from '@components/LayoutComponents/Menu/MenuTop'
import { BackTop, Layout } from 'antd'
import React from 'react'

import styles from './style.module.scss'

const MainLayout = ({ children }) => {
  return (
    <>
      <BackTop />
      <Layout className="settings__menuTop">
        <MenuLeft />
        <Layout>
          <Layout.Header className={styles.stickyNav}>
            <MenuTop />
          </Layout.Header>
          <Layout.Content style={{ position: 'relative' }}>
            {children}
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  )
}

export default MainLayout
