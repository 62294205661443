export default {
  edit: "provider:edit",
  create: "provider:create",
  delete: "provider:delete",
  view: "provider:view",
  publisher: {
    edit: "provider:publisher:edit",
    create: "provider:publisher:create",
    delete: "provider:publisher:delete",
    view: "provider:publisher:view",
  },
};
