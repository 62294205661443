const PostPermissions = {
  edit: 'post:edit',
  create: 'post:create',
  delete: 'post:delete',
  view: 'post:view',
  publisher: {
    edit: 'post:publisher:edit',
    create: 'post:publisher:create',
    delete: 'post:publisher:delete',
    view: 'post:publisher:view',
  },
}

export default PostPermissions
