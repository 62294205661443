import { useAuth0 } from '@auth0/auth0-react'
import { IS_NEW_PROPERTY_KEY } from '@constants/fieldKeys'
import { useNetworkContext } from '@contexts/network'
import { useProvidersAndTaxonomiesCountsLazyQuery } from '@generated/graphql'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'

function reducer(state, action) {
  switch (action.type) {
    case 'set_counts':
      return {
        ...state,
        providersCount: action.payload.providersCount,
        taxonomyItemsCount: action.payload.taxonomyItemsCount,
        loading: false,
      }
    case 'set_loading':
      if (action.payload === state.loading) {
        return state
      }
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const CountsContext = createContext()

export const useCountsContext = () => useContext(CountsContext)

function init() {
  return {
    providersCount: 0,
    taxonomyItemsCount: 0,
    loading: true,
  }
}

const createWhere = networkId => ({
  AND: [
    {
      networkId: {
        equals: networkId,
      },
    },
    {
      properties: {
        some: {
          key: {
            equals: IS_NEW_PROPERTY_KEY,
          },
          data: {
            contains: 'true',
          },
        },
      },
    },
  ],
})

const CountsProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const { getCurrentNetwork, loading: networkLoading } = useNetworkContext()

  const [state, dispatch] = useReducer(reducer, null, init)

  const [queryCounts, { refetch }] = useProvidersAndTaxonomiesCountsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ providersCount, taxonomyItemsCount }) => {
      console.log(
        '%c Allan: Finished loading counts ',
        'background: #222; color: #bada55',
      )

      setCounts(dispatch, { providersCount, taxonomyItemsCount })
    },
    onError: error => {
      console.error('Unable to load counts', error)
    },
  })

  useEffect(() => {
    const networkId = getCurrentNetwork()?.id

    if (!isAuthenticated || networkLoading || !networkId) {
      setCounts(dispatch, init())
      return
    }

    const where = createWhere(networkId)

    setLoading(dispatch, true)
    queryCounts({
      variables: {
        providerWhere: where,
        taxonomyItemWhere: where,
      },
    })
  }, [isAuthenticated, queryCounts, networkLoading, getCurrentNetwork])

  const fetchCounts = useCallback(async () => {
    const networkId = getCurrentNetwork()?.id

    if (!networkId) {
      setCounts(dispatch, init())
      return
    }

    setLoading(dispatch, true)

    const where = createWhere(networkId)
    const {
      data: { providersCount, taxonomyItemsCount },
    } = await refetch({
      providerWhere: where,
      taxonomyItemWhere: where,
    })

    setCounts(dispatch, { providersCount, taxonomyItemsCount })
  }, [refetch, getCurrentNetwork])

  const value = useMemo(
    () => ({
      state,
      loading: state.loading,
      fetchCounts,
    }),
    [state, fetchCounts],
  )

  return (
    <CountsContext.Provider value={value}>{children}</CountsContext.Provider>
  )
}

export default CountsProvider

function setCounts(dispatch, counts) {
  dispatch({
    type: 'set_counts',
    payload: counts,
  })
}

function setLoading(dispatch, loading) {
  dispatch({
    type: 'set_loading',
    payload: loading,
  })
}
