import { useAuth0 } from '@auth0/auth0-react'
import { VERTICAL_USER_SELECT_STORE_KEY } from '@components/LayoutComponents/TopBar/VerticalSelect/constants'
import { useNetworkContext } from '@contexts/network'
import StorageHelper from '@modules/storage'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'

import {
  checkAdditionalFlag,
  DEFAULT_KEY_FOR_CONTEXT,
  getPersistedSelector,
  usePersistSelector,
} from './hooks/usePersistSelector'

const STORAGE_KEY = 'currentVertical'

function reducer(state, action) {
  switch (action.type) {
    case 'set_verticals':
      if (action.payload === state.verticals) {
        return state
      }
      return { ...state, verticals: action.payload, loading: false }
    case 'set_current_vertical':
      if (action.payload === state.currentVertical) {
        return state
      }
      return { ...state, currentVertical: action.payload }
    case 'set_loading':
      if (action.payload === state.loading) {
        return state
      }
      return { ...state, loading: action.payload }
    case 'set_selector_config':
      if (
        action.payload.selected === state.selectorConfig.selected &&
        action.payload.disabled === state.selectorConfig.disabled
      ) {
        return state
      }
      return { ...state, selectorConfig: action.payload }
    default:
      throw new Error()
  }
}

const VerticalContext = createContext()

export const useVerticalContext = () => useContext(VerticalContext)

function init() {
  return {
    verticals: [],
    currentVertical: getPersistedSelector(STORAGE_KEY),
    loading: true,
    selectorConfig: {},
  }
}

const VerticalProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const { getCurrentNetwork, loading: networkLoading } = useNetworkContext()

  const [state, dispatch] = useReducer(reducer, null, init)
  usePersistSelector(STORAGE_KEY, state.currentVertical)

  const setCurrentVertical = useCallback(id => {
    dispatch({
      type: 'set_current_vertical',
      payload: id,
    })
  }, [])

  useEffect(() => {
    if (networkLoading) {
      setLoading(dispatch, true)
    }
  }, [networkLoading])

  useEffect(() => {
    if (!isAuthenticated || networkLoading) {
      return
    }

    const currentNetwork = getCurrentNetwork()
    if (!currentNetwork) {
      setVerticals(dispatch, [])
      return
    }

    setVerticals(dispatch, currentNetwork.verticals)
  }, [getCurrentNetwork, networkLoading, isAuthenticated])

  const getCurrentVertical = useCallback(() => {
    if (!state.currentVertical) {
      return DEFAULT_KEY_FOR_CONTEXT
    }

    return state.verticals.find(
      vertical => vertical.id === state.currentVertical,
    )
  }, [state.currentVertical, state.verticals])

  const setSelectorConfig = useCallback(config => {
    dispatch({
      type: 'set_selector_config',
      payload: config,
    })
  }, [])

  const validate = useCallback(
    value => {
      return (
        checkAdditionalFlag(value) ||
        state.verticals.some(vertical => vertical.id === value)
      )
    },
    [state.verticals],
  )

  const getDefault = useCallback(() => {
    return state.verticals[0]?.id || DEFAULT_KEY_FOR_CONTEXT
  }, [state.verticals])

  const value = useMemo(() => {
    return {
      state,
      loading: state.loading,
      setCurrentVertical,
      getCurrentVertical,
      setSelectorConfig,
      validate,
      getDefault,
    }
  }, [
    state,
    getCurrentVertical,
    setCurrentVertical,
    setSelectorConfig,
    validate,
    getDefault,
  ])

  return (
    <VerticalContext.Provider value={value}>
      {children}
    </VerticalContext.Provider>
  )
}

export default VerticalProvider

function setVerticals(dispatch, verticals) {
  dispatch({
    type: 'set_verticals',
    payload: verticals,
  })
}

function setLoading(dispatch, loading) {
  dispatch({
    type: 'set_loading',
    payload: loading,
  })
}

export function getSelectedValue() {
  return StorageHelper.getItem(VERTICAL_USER_SELECT_STORE_KEY)
}

export function setSelectedValue(value) {
  return StorageHelper.setItem(VERTICAL_USER_SELECT_STORE_KEY, value)
}
