import { useContextURLConnection } from '@components/LayoutComponents/TopBar/hooks/useContextURLConnection'
import {
  getSiteLanguageTag,
  setSelectedValue,
  useSiteContext,
} from '@contexts/site'
import { getSearchParam } from '@utils'
import { memo, useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { SITE_URL_KEY } from '../../constants'

const SiteURLConnection = () => {
  const {
    loading,
    state: { sites },
    getCurrentSite,
    setCurrentSite,
    validate,
    getDefault,
  } = useSiteContext()

  const isProcessed = useRef(false)
  const location = useLocation()
  useEffect(() => {
    if (isProcessed.current || loading) {
      return
    }
    isProcessed.current = true

    let urlParam = getSearchParam(location.search, SITE_URL_KEY)
    if (urlParam) {
      const siteLanguageTag = getSiteLanguageTag(sites, urlParam)
      setSelectedValue(siteLanguageTag)
    }
    // should run only once when page is opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const getCurrent = useCallback(() => {
    const currentSite = getCurrentSite()
    return currentSite?.id || currentSite
  }, [getCurrentSite])

  useContextURLConnection({
    loading,
    getCurrent,
    setCurrent: setCurrentSite,
    validate,
    getDefault,
    searchParamKey: SITE_URL_KEY,
  })

  return null
}

export default memo(SiteURLConnection)
