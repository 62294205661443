import { useAuth0 } from '@auth0/auth0-react'
import { DEFAULT_LANGUAGE } from '@constants'
import { useLanguagesLazyQuery } from '@generated/graphql'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

function reducer(state, action) {
  switch (action.type) {
    case 'set_languages':
      return { ...state, languages: action.payload, loading: false }
    case 'set_loading':
      if (action.payload === state.loading) {
        return state
      }
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const LanguagesContext = createContext()

export const useLanguagesContext = () => useContext(LanguagesContext)

function init() {
  return {
    languages: [],
    loading: true,
  }
}

const LanguagesProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()

  const [state, dispatch] = useReducer(reducer, null, init)

  const [queryLanguages, { refetch }] = useLanguagesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ languages }) => {
      console.log(
        '%c Allan: Finished loading languages ',
        'background: #222; color: #bada55',
      )

      setLanguages(dispatch, languages)
      setLoading(dispatch, false)
    },
    onError: error => {
      console.error('Unable to load languages', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    setLoading(dispatch, true)
    queryLanguages()
  }, [isAuthenticated, queryLanguages])

  const getLanguageIdByTag = useCallback(
    languageTag =>
      state.languages.find(language => language.languageTag === languageTag)
        ?.id,
    [state.languages],
  )

  const defaultLanguageId = getLanguageIdByTag(DEFAULT_LANGUAGE.languageTag)

  const fetchLanguages = useCallback(() => {
    setLoading(dispatch, true)
    refetch()
  }, [refetch])

  return (
    <LanguagesContext.Provider
      value={{
        state,
        loading: state.loading,
        fetchLanguages,
        getLanguageIdByTag,
        defaultLanguageId,
      }}>
      {children}
    </LanguagesContext.Provider>
  )
}

export default LanguagesProvider

function setLanguages(dispatch, languages) {
  dispatch({
    type: 'set_languages',
    payload: languages,
  })
}

function setLoading(dispatch, loading) {
  dispatch({
    type: 'set_loading',
    payload: loading,
  })
}
