import { useAuth0 } from '@auth0/auth0-react'
import { SITE_USER_SELECT_STORE_KEY } from '@components/LayoutComponents/TopBar/SiteSelect/constants'
import { DEFAULT_LANGUAGE } from '@constants'
import { useVerticalContext } from '@contexts/vertical'
import { useSiteContextSlimSitesLazyQuery } from '@generated/graphql'
import StorageHelper from '@modules/storage'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'

import {
  checkAdditionalFlag,
  DEFAULT_KEY_FOR_CONTEXT,
  getKeyForValue,
  getPersistedSelector,
  usePersistSelector,
} from './hooks/usePersistSelector'

const STORAGE_KEY = 'currentSite'

function reducer(state, action) {
  switch (action.type) {
    case 'set_sites':
      return { ...state, sites: action.payload, loading: false }
    case 'set_current_site':
      if (state.currentSite === action.payload) {
        return state
      }
      return { ...state, currentSite: action.payload }
    case 'set_current_language':
      if (state.currentLanguage === action.payload) {
        return state
      }
      return { ...state, currentLanguage: action.payload }
    case 'set_loading':
      if (action.payload === state.loading) {
        return state
      }
      return { ...state, loading: action.payload }
    case 'set_selector_config':
      if (
        action.payload.selected === state.selectorConfig.selected &&
        action.payload.disabled === state.selectorConfig.disabled
      ) {
        return state
      }
      return { ...state, selectorConfig: action.payload }
    default:
      throw new Error()
  }
}

const SiteContext = createContext()

export const useSiteContext = () => useContext(SiteContext)

function init() {
  return {
    sites: [],
    currentSite: getPersistedSelector(STORAGE_KEY),
    currentLanguage: DEFAULT_LANGUAGE,
    loading: true,
    selectorConfig: {},
  }
}

const SiteProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const { getCurrentVertical, loading: verticalsLoading } = useVerticalContext()

  const [state, dispatch] = useReducer(reducer, null, init)
  usePersistSelector(STORAGE_KEY, state.currentSite)

  const setCurrentSite = useCallback(id => {
    dispatch({
      type: 'set_current_site',
      payload: id,
    })
  }, [])

  const [querySites, { called, refetch }] = useSiteContextSlimSitesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ sites }) => {
      setSitesHelper(dispatch, sites)
    },
    onError: error => {
      console.error('Unable to load sites', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || verticalsLoading) {
      return
    }

    const currentVertical = getCurrentVertical()
    if (!currentVertical?.id) {
      setSites(dispatch, [])
      return
    }

    setLoading(dispatch, true)

    if (called) {
      refetch({
        where: {
          verticalId: {
            equals: currentVertical.id,
          },
        },
      }).then(({ data }) => {
        setSitesHelper(dispatch, data.sites)
      })
    } else {
      querySites({
        variables: {
          where: {
            verticalId: {
              equals: currentVertical.id,
            },
          },
        },
      })
    }
  }, [getCurrentVertical, isAuthenticated, querySites, verticalsLoading])

  const getCurrentSite = useCallback(() => {
    if (!state.currentSite) {
      return DEFAULT_KEY_FOR_CONTEXT
    }

    return state.sites.find(site => site.id === state.currentSite)
  }, [state.currentSite, state.sites])

  const setCurrentLanguage = useCallback(language => {
    dispatch({
      type: 'set_current_language',
      payload: language,
    })
  }, [])

  const getCurrentLanguage = useCallback(() => {
    return (
      getCurrentSite()?.language || state.currentLanguage || DEFAULT_LANGUAGE
    )
  }, [getCurrentSite, state.currentLanguage])

  const fetchSites = useCallback(() => {
    setLoading(dispatch, true)
    refetch().then(({ data }) => {
      setSitesHelper(dispatch, data.sites)
    })
  }, [refetch])

  const setSelectorConfig = useCallback(config => {
    dispatch({
      type: 'set_selector_config',
      payload: config,
    })
  }, [])

  const validate = useCallback(
    value => {
      return (
        checkAdditionalFlag(value) ||
        state.sites.some(site => site.id === value)
      )
    },
    [state.sites],
  )

  const getDefault = useCallback(() => {
    return getSelectedValue(state.sites)?.id || DEFAULT_KEY_FOR_CONTEXT
  }, [state.sites])

  const value = useMemo(() => {
    return {
      state,
      loading: state.loading,
      setCurrentSite,
      getCurrentSite,
      getCurrentLanguage,
      setCurrentLanguage,
      fetchSites,
      validate,
      setSelectorConfig,
      getDefault,
    }
  }, [
    fetchSites,
    getCurrentSite,
    setCurrentSite,
    getCurrentLanguage,
    setCurrentLanguage,
    state,
    validate,
    setSelectorConfig,
    getDefault,
  ])

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

export default SiteProvider

function setSites(dispatch, sites) {
  dispatch({
    type: 'set_sites',
    payload: sites,
  })
}

function setLoading(dispatch, loading) {
  dispatch({
    type: 'set_loading',
    payload: loading,
  })
}

function setSitesHelper(dispatch, sites) {
  console.log(
    '%c Allan: Finished loading sites ',
    'background: #222; color: #bada55',
  )
  setSites(dispatch, sites)
}

export function sameLanguageSiteId(sites, languageTag) {
  return (
    sites.find(site => site.language.languageTag === languageTag)?.id ||
    sites.find(
      site => site.language.languageTag === DEFAULT_LANGUAGE.languageTag,
    )?.id ||
    null
  )
}

export function getSiteLanguageTag(sites, siteId) {
  return sites.find(site => site.id === siteId)?.language.languageTag
}

export function getSelectedValue(sites) {
  const storedLanguage = StorageHelper.getItem(SITE_USER_SELECT_STORE_KEY)

  let additionalKeyOrTag = getKeyForValue(storedLanguage)
  if (additionalKeyOrTag === undefined) {
    additionalKeyOrTag = storedLanguage
  }

  return sameLanguageSiteId(sites, additionalKeyOrTag)
}

export function setSelectedValue(value) {
  return StorageHelper.setItem(SITE_USER_SELECT_STORE_KEY, value)
}
