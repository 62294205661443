export default {
  edit: "taxonomy:edit",
  create: "taxonomy:create",
  delete: "taxonomy:delete",
  view: "taxonomy:view",
  publisher: {
    edit: "taxonomy:publisher:edit",
    create: "taxonomy:publisher:create",
    delete: "taxonomy:publisher:delete",
    view: "taxonomy:publisher:view",
  },
};
