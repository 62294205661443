import { useAuth0 } from '@auth0/auth0-react'
import { TEMPLATES_KEY } from '@constants/storageHelper'
import { useNetworkContext } from '@contexts/network'
import { useTemplatesLazyQuery } from '@generated/graphql'
import StorageHelper from '@modules/storage'
import { Modal } from 'antd'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'

const initialState = {
  loading: false,
  templates: StorageHelper.getObject(TEMPLATES_KEY) || [],
}

const TemplateContext = createContext()

export const useTemplateContext = () => useContext(TemplateContext)

/**
 *
 * @param {{ type, subtype?, templates? }} options
 * @returns
 */
export const getTemplate = ({ type, subtype, templates = [] }) => {
  return (
    templates.find(template => {
      // eslint-disable-next-line eqeqeq
      return template.type === type && template.subtype == subtype
    }) || {}
  )
}

export const getTemplates = ({ type, templates = [] }) => {
  return (
    templates.filter(template => {
      return template.type === type
    }) || []
  )
}

/**
 *
 * @param {{ type, subtype? }} options
 * @returns
 */
export const useTemplate = ({ type, subtype }) => {
  const {
    state: { templates },
  } = useTemplateContext()

  return useMemo(() => {
    return getTemplate({ type, subtype, templates })
  }, [type, subtype, templates])
}

export const useTemplates = ({ type }) => {
  const {
    state: { templates },
  } = useTemplateContext()

  return useMemo(() => {
    return getTemplates({ type, templates })
  }, [type, templates])
}

const TemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { getCurrentNetwork } = useNetworkContext()

  const { isAuthenticated } = useAuth0()

  const [queryTemplates] = useTemplatesLazyQuery({
    onCompleted: ({ templates }) => {
      console.log(
        '%c Allan: Finished loading templates ',
        'background: #222; color: #bada55',
      )

      setTemplates(dispatch, templates)
    },
    onError: error => {
      Modal.error({
        title: 'Templates failed to load',
        content: 'Please reload page',
      })
      console.error('Unable to load templates', error)
    },
  })

  useEffect(() => {
    const currentNetwork = getCurrentNetwork()
    if (!currentNetwork || !isAuthenticated) {
      return
    }

    setLoading(dispatch, true)
    queryTemplates({
      variables: {
        where: {
          networkId: {
            equals: currentNetwork.id,
          },
        },
      },
    })
  }, [getCurrentNetwork, isAuthenticated, queryTemplates])

  return (
    <TemplateContext.Provider
      value={{
        state,
        loading: state.loading,
      }}>
      {children}
    </TemplateContext.Provider>
  )
}

export default TemplateProvider

function setTemplates(dispatch, templates) {
  StorageHelper.setObject(TEMPLATES_KEY, templates)
  dispatch({
    type: 'set_templates',
    payload: templates,
  })
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_templates':
      return {
        ...state,
        templates: Array.from(action.payload) || [],
        loading: false,
      }
    case 'set_loading':
      if (action.payload === state.loading) {
        return state
      }
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

function setLoading(dispatch, loading) {
  dispatch({
    type: 'set_loading',
    payload: loading,
  })
}
