import { useSettingsContext } from '@contexts/settings'
import { useTemplateContext } from '@contexts/template'
import React, { createContext, useContext, useEffect, useReducer } from 'react'

import * as data from './data'

const initialState = {
  menuLeftData: [],
  menuTopData: [],
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_data':
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

const MenuContext = createContext()

export const useMenuContext = () => useContext(MenuContext)

const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    state: { templates },
  } = useTemplateContext()
  const {
    state: { language },
  } = useSettingsContext()

  useEffect(() => {
    ;(async () => {
      const menuLeftData = await data.getLeftMenuData(templates, language)
      const menuTopData = await data.getTopMenuData(templates, language)

      dispatch({
        type: 'set_data',
        payload: { menuLeftData, menuTopData },
      })
    })()
  }, [language, templates])

  const value = {
    state,
    dispatch,
  }

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

export default MenuProvider
