import Localization from '@components/LayoutComponents/Localization'
import CountsProvider from '@contexts/counts'
import LanguagesProvider from '@contexts/languages'
import LiveUsersProvider from '@contexts/liveUsers'
import MenuProvider from '@contexts/menu'
import NetworkProvider from '@contexts/network'
import NotificationProvider from '@contexts/notifications'
import SettingsProvider from '@contexts/settings'
import SiteProvider from '@contexts/site'
import TemplateProvider from '@contexts/template'
import VerticalProvider from '@contexts/vertical'
import ApolloProviderConfigured from '@modules/apollo'
import Auth0ProviderConfigured from '@modules/auth0'
import Router from '@pages/router'
import React from 'react'

function App() {
  return (
    <Auth0ProviderConfigured>
      <ApolloProviderConfigured>
        <LanguagesProvider>
          <SettingsProvider>
            <NetworkProvider>
              <VerticalProvider>
                <SiteProvider>
                  <CountsProvider>
                    <TemplateProvider>
                      <MenuProvider>
                        <LiveUsersProvider>
                          <NotificationProvider>
                            <Localization>
                              <Router />
                            </Localization>
                          </NotificationProvider>
                        </LiveUsersProvider>
                      </MenuProvider>
                    </TemplateProvider>
                  </CountsProvider>
                </SiteProvider>
              </VerticalProvider>
            </NetworkProvider>
          </SettingsProvider>
        </LanguagesProvider>
      </ApolloProviderConfigured>
    </Auth0ProviderConfigured>
  )
}

export default App
