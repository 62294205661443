import Loader from '@components/LayoutComponents/Loader'
import NetworkURLConnection from '@components/LayoutComponents/TopBar/NetworkSelect/components/NetworkURLConnection'
import SiteURLConnection from '@components/LayoutComponents/TopBar/SiteSelect/components/SiteURLConnection'
import VerticalURLConnection from '@components/LayoutComponents/TopBar/VerticalSelect/components/VerticalURLConnection'
import IndexLayout from '@components/layouts'
import Notifications from '@components/Notifications'
import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'

import TopSelectorSet from './components/TopSelectorSet'
import { routes } from './routes'

const Router = () => {
  const renderRoutes = routes.reduce(
    (routes, route) => renderRoute(routes, route, ''),
    [],
  )

  return (
    <HashRouter>
      <IndexLayout>
        <Notifications>
          <NetworkURLConnection />
          <VerticalURLConnection />
          <SiteURLConnection />
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/verticals/list" />}
            />
            {renderRoutes}
          </Switch>
        </Notifications>
      </IndexLayout>
    </HashRouter>
  )
}

export default Router

function renderRoute(routes, route, parentPath) {
  const path = parentPath + '/' + route.path

  if (route.component) {
    routes.push(
      <Route
        key={path}
        path={path}
        render={() =>
          LazyRendering(route.component.Component, route.component.params)
        }
        exact={true}
      />,
    )
  }

  route.children?.reduce(
    (routes, childRoute) => renderRoute(routes, childRoute, path),
    routes,
  )

  return routes
}

function LazyRendering(Component, _props = { topSelector: undefined }) {
  return (
    <React.Suspense fallback={<Loader />}>
      <TopSelectorSet topSelector={_props.topSelector}>
        <Component {..._props} />
      </TopSelectorSet>
    </React.Suspense>
  )
}
