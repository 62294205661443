import { SITE_USER_SELECT_STORE_KEY } from '@components/LayoutComponents/TopBar/SiteSelect/constants'
import { DEFAULT_LANGUAGE } from '@constants'
import {
  ContextType,
  FieldType,
  ResourceType,
  StringItemStatus,
  TemplateType,
} from '@generated/graphql'
import {
  UpsertPost,
  UpsertProduct,
  UpsertProvider,
  UpsertSite,
  UpsertTaxonomyItem,
} from '@generated/operations'
import { triggerErrorMessage } from '@modules/messages'
import StorageHelper from '@modules/storage'
import noop from 'lodash/noop'

/**
 *
 * @param {{key: string, data: object, template: Template, id: string, type: ("Provider"|"Product"|"TaxonomyItem"|"Post"|"Site")}}
 */

// TODO: make work with new upserts
export const setPropertiesOnResource = async ({
  client,
  data,
  id, // Id of resource to update
  type, // Type of resource | provider, product etc
}) => {
  const { currentNetwork, currentVertical, currentSite } = window

  let mutation,
    current = {
      network: currentNetwork?.id,
      vertical: currentVertical?.id,
      site: currentSite?.id, // TODO: check if this param is required
    }

  if (type === 'Site') {
    mutation = UpsertSite
    current = {
      network: currentNetwork?.id,
      vertical: currentVertical?.id,
    }
  } else if (type === 'Provider') {
    mutation = UpsertProvider
  } else if (type === 'TaxonomyItem') {
    mutation = UpsertTaxonomyItem
  } else if (type === 'Post') {
    mutation = UpsertPost
  } else if (type === 'TemplateField') {
    throw new Error('TemplateField not implemented in setDataOnField')
  } else if (type === 'Product') {
    mutation = UpsertProduct
  }

  await client.mutate({
    mutation: mutation,
    variables: {
      where: {
        id,
      },
      current,
      data: {
        properties: {
          items: Object.keys(data).map(key => {
            return {
              key,
              data: data?.[key],
            }
          }),
        },
      },
    },
  })
}

/**
 *
 * @param {{field: TemplateField, currentNetwork: string, currentVertical: string, currentSite: string}}
 */

export const getContextId = ({
  field,
  currentNetwork,
  currentVertical,
  currentSite,
}) => {
  if (!field) return null
  if (field.context === ContextType.Network) return `${currentNetwork?.id}`
  if (field.context === ContextType.Vertical) return `${currentVertical?.id}`
  if (field.context === ContextType.Site) return `${currentSite?.id}`
}

/**
 * @param {string} key
 * @param {TemplateField[]} fields
 */

export const getFieldByKey = (key, fields = []) => {
  return fields.find(field => field.key === key)
}

/**
 * @param {FieldType} type
 * @param {TemplateField[]} fields
 */

export const getFieldsOfType = (type, fields) => {
  return fields?.filter(field => field.type === type)
}

/**
 * @param {ResourceType} resourceType
 * @param {TemplateField[]} fields
 */

export const getFieldsByResourceType = (resourceType, fields = []) => {
  return fields.filter(field => field.resourceType === resourceType)
}

/**
 * @param {TemplateField} field
 * @returns {ResourceType}
 */

export const getFieldResourceType = field => {
  if (!field) return null
  switch (field.type) {
    case FieldType.Url:
    case FieldType.Slug:
    case FieldType.Number:
    case FieldType.Post:
    case FieldType.Page:
    case FieldType.Taxonomyitem:
    case FieldType.Product:
    case FieldType.Provider:
    case FieldType.Site:
    case FieldType.Vertical:
    case FieldType.Network:
    case FieldType.Level:
    case FieldType.Template:
    case FieldType.Templatefield:
    case FieldType.Property:
    case FieldType.Language:
    case FieldType.Html:
    case FieldType.GeoString:
    case FieldType.GeoNumber:
    case FieldType.Boolean:
    case FieldType.Mediaitem:
    case FieldType.String:
      return ResourceType.Property
    case FieldType.Singleline:
    case FieldType.Multiline:
    case FieldType.Markdown:
    case FieldType.Stringtemplate:
      return ResourceType.Content
    default:
      return
  }
}

export function filterOption(inputValue, option) {
  return Object.values(option.searchables).some(value =>
    value.toLowerCase().includes(inputValue.toLowerCase()),
  )
}

export function isPublisherReady(type) {
  return (
    type === TemplateType.Provider ||
    type === TemplateType.Taxonomy ||
    type === TemplateType.Post
  )
}

export function getPublisherLink(location) {
  return location.pathname.replace(/list|view/, 'publisher')
}

export function getAddLink(location) {
  return location.pathname.replace(/list|view/, 'add')
}

export function getPublisherLinkWithId(location, id) {
  return getPublisherLink(location) + `/${id}`
}

export function getEditorLink(location) {
  return location.pathname.replace(/list|view/, 'edit')
}

export function getEditorLinkWithId(location, id) {
  return getEditorLink(location) + `/${id}`
}

export function requiredValidation(value) {
  if (!value) {
    return 'Required'
  }
}

export function addRequiredValidation(isRequired) {
  if (isRequired) {
    return requiredValidation
  }

  return noop
}

export function createResourceRedirect(
  pathname,
  history,
  location,
  setCurrentSite,
) {
  StorageHelper.setItem(
    SITE_USER_SELECT_STORE_KEY,
    DEFAULT_LANGUAGE.languageTag,
  )
  setCurrentSite(DEFAULT_LANGUAGE.languageTag) // TODO: fix, setSite should work with id
  history.push({
    pathname,
    search: location.search,
  })
}

/**
 * Removes 'http://' and 'https://' from url
 * @param {string} [url]
 * @returns {string}
 */
export function removeProtocol(url) {
  return url?.replace(/^http(?:s)?:\/\//, '') || ''
}

export const validateURL = (isJsonFormat = false) => {
  return value => {
    const url = isJsonFormat ? value && JSON.parse(value)?.value : value
    if (url && !/^https:\/\/.+\..+/.test(url)) {
      return 'Please enter a valid URL'
    }
  }
}

export const checkDisabledPropertyField = currentLanguageTag => {
  return currentLanguageTag !== DEFAULT_LANGUAGE.languageTag
}

export function hasDraftString(strings) {
  return strings?.some(checkDraft)
}

export function hasDraftStringByContext(strings, contextId) {
  return strings?.some(
    string => checkDraft(string) && string.contextId === contextId,
  )
}

export function checkDraft(string) {
  return string.status === StringItemStatus.Draft
}

export const safeJsonParse = data => {
  try {
    return JSON.parse(data)
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function setSearchParam(history, key, value) {
  const searchParams = new URLSearchParams(history.location.search)
  if (searchParams.get(key) === value) {
    return
  }

  searchParams.set(key, value)
  history.push({
    search: searchParams.toString(),
  })
}

export function getSearchParam(search, key) {
  const searchParams = new URLSearchParams(search)

  return searchParams.get(key)
}

export const onError = error => {
  return triggerErrorMessage({
    displayType: 'message',
    duration: 3000,
    message: error.message.replace('GraphQL error: ', '').trim(),
  })
}
