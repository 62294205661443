import { RocketOutlined } from '@ant-design/icons'
import { LinkWithQuery } from '@components/LinkWithQuery'
import { useCheckPermissions } from '@components/Permissions/useCheckPermissions'
import { DEFAULT_LANGUAGE } from '@constants'
import { NAME_KEY } from '@constants/fieldKeys'
import { useVerticalContext } from '@contexts/vertical'
import { getStringFromContent } from '@modules/content'
import StorageHelper from '@modules/storage'
import { setSearchParam } from '@utils'
import { permissions } from '@wdnsolutions/auth-helpers'
import { Dropdown, Menu } from 'antd'
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import styles from '../style.module.scss'
import { VERTICAL_URL_KEY, VERTICAL_USER_SELECT_STORE_KEY } from './constants'

const VerticalSelect = () => {
  const {
    getCurrentVertical,
    state: { verticals, selectorConfig },
  } = useVerticalContext()
  const currentVertical = getCurrentVertical()

  const menu = useRenderMenu({
    verticals,
    currentVertical,
  })

  return (
    <Dropdown
      disabled={selectorConfig.disabled}
      overlay={menu}
      trigger={['hover']}
      placement="bottomLeft">
      <div
        className={classNames(
          styles.dropdown,
          selectorConfig.disabled && styles.disabled,
        )}>
        {renderVertical(currentVertical)}
      </div>
    </Dropdown>
  )
}

export default memo(VerticalSelect)

function renderVertical(currentVertical) {
  if (!currentVertical) {
    return (
      <span className="text-uppercase text-white">
        <FormattedMessage id="topBar.allVerticals" />
      </span>
    )
  }

  return (
    <span className="badge badge-pill badge-success d-inline ml-3 small">
      {getStringFromContent(NAME_KEY, {
        content: currentVertical?.content,
        language: DEFAULT_LANGUAGE.languageTag,
      }) || currentVertical?.id}
    </span>
  )
}

function useRenderMenu({ verticals, currentVertical }) {
  const history = useHistory()
  const isAddingVerticalAllowed = useCheckPermissions([
    permissions.vertical.create,
  ])

  const menuItems = useMemo(() => {
    const menuItems: any[] = [
      {
        key: 'all',
        label: 'All verticals',
      },
      {
        key: 'divider1',
        type: 'divider',
      },
    ]

    verticals?.forEach(vertical => {
      menuItems.push({
        key: vertical.id,
        label:
          getStringFromContent(NAME_KEY, {
            content: vertical?.content,
            language: DEFAULT_LANGUAGE.languageTag,
          }) || vertical?.id,
      })
    })

    if (verticals?.length) {
      menuItems.push({
        key: 'divider2',
        type: 'divider',
      })
    }

    if (isAddingVerticalAllowed) {
      menuItems.push({
        key: 'add',
        label: (
          <LinkWithQuery to="/verticals/add">
            <RocketOutlined className="mr-2" /> Add vertical
          </LinkWithQuery>
        ),
      })
    }

    return menuItems
  }, [verticals, isAddingVerticalAllowed])

  return (
    <Menu
      selectable={false}
      selectedKeys={[currentVertical?.id]}
      onClick={({ key }) => {
        if (key === 'add') {
          return
        }

        StorageHelper.setItem(VERTICAL_USER_SELECT_STORE_KEY, key)
        setSearchParam(history, VERTICAL_URL_KEY, key)
      }}
      items={menuItems}
    />
  )
}
