import { useContextURLConnection } from '@components/LayoutComponents/TopBar/hooks/useContextURLConnection'
import { setSelectedValue, useNetworkContext } from '@contexts/network'
import { getSearchParam } from '@utils'
import { memo, useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { NETWORK_URL_KEY } from '../../constants'

const NetworkURLConnection = () => {
  const {
    loading,
    getCurrentNetwork,
    setCurrentNetwork,
    validate,
    getDefault,
  } = useNetworkContext()

  const isProcessed = useRef(false)
  const location = useLocation()
  useEffect(() => {
    if (isProcessed.current || loading) {
      return
    }
    isProcessed.current = true
    let urlParam = getSearchParam(location.search, NETWORK_URL_KEY)
    if (urlParam) {
      setSelectedValue(urlParam)
    }
    // should run only once when page is opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const getCurrent = useCallback(() => {
    const currentNetwork = getCurrentNetwork()
    return currentNetwork?.id || currentNetwork
  }, [getCurrentNetwork])

  useContextURLConnection({
    loading,
    getCurrent,
    setCurrent: setCurrentNetwork,
    validate,
    getDefault,
    searchParamKey: NETWORK_URL_KEY,
  })

  return null
}

export default memo(NetworkURLConnection)
