import { routes } from '@pages/router/routes'

export async function getLeftMenuData(templates, language) {
  return getMenuData('left', templates, language)
}

export async function getTopMenuData(templates, language) {
  return getMenuData('top', templates, language)
}

export function getParentMenuItem(menuData, pathname) {
  const parentPath = pathname.match(/\/[^/]*/)[0]

  return menuData.find(item => item.key?.startsWith(parentPath))
}

function getMenuData(type, templates, language) {
  const root = { item: { children: [] }, url: '' }

  routes.forEach(route =>
    prepareMenuItem(root, route, type, templates, language),
  )

  return root.item.children
}

function prepareMenuItem(parent, route, type, templates, language) {
  const routeUrl = parent.url + '/' + route.path

  if (!route.menu?.[type]) {
    const nextParent = { item: parent.item, url: routeUrl }

    route.children?.forEach(route =>
      prepareMenuItem(nextParent, route, type, templates, language),
    )
    return
  }

  let menuConfig = route.menu[type]

  if (typeof menuConfig === 'function') {
    menuConfig = menuConfig(templates)
  }

  menuConfig.forEach(config => {
    if (config.divider) {
      parent.item.children = parent.item.children || []
      parent.item.children.push(config)
      return
    }

    let url = routeUrl
    if (config.url) {
      url = parent.url + '/' + config.url
    }

    let item = {
      key: url,
      title: config.title(templates, language),
      permissions: config.permissions,
      icon: config.icon,
      renderLabel: config.renderLabel,
    }

    if (route.component) {
      item.url = url
    }

    parent.item.children = parent.item.children || []
    parent.item.children.push(item)

    const nextParent = { item, url }

    route.children?.forEach(route =>
      prepareMenuItem(nextParent, route, type, templates, language),
    )
  })
}
