import { usePermissions } from '@components/Permissions'
import { APP_MENU_SELECTED_KEY } from '@constants/storageHelper'
import { useMenuContext } from '@contexts/menu'
import { getParentMenuItem } from '@contexts/menu/data'
import { Layout, Menu } from 'antd'
import find from 'lodash/find'
import React, { useEffect, useMemo, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useLocation } from 'react-router-dom'
import store from 'store'

import {
  flattenItems,
  generateMenuItems,
  getInitialParentKeys,
} from '../helpers'
import Logo from './components/Logo'
import { generateItem } from './helpers'
import styles from './style.module.scss'

const { Sider } = Layout

const MenuLeft = () => {
  const {
    state: { menuLeftData: menuData },
  } = useMenuContext()

  const [selectedKey, setSelectedKey] = useState(
    store.get(APP_MENU_SELECTED_KEY),
  )

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)

  const { allowed } = usePermissions()

  const location = useLocation()

  useEffect(() => {
    const selectedItem =
      find(flattenItems(menuData, 'children'), ['url', location.pathname]) ||
      getParentMenuItem(menuData, location.pathname)

    if (selectedItem?.key) {
      setSelectedKey(selectedItem.key)
    }
  }, [location.pathname, menuData])

  const menuChildren = useMemo(
    () => generateMenuItems(allowed, menuData, generateItem, isMenuCollapsed),
    [allowed, menuData, isMenuCollapsed],
  )

  const onCollapse = (_, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    setIsMenuCollapsed(!isMenuCollapsed)
  }

  const handleClick = e => {
    store.set(APP_MENU_SELECTED_KEY, e.key)
    setSelectedKey(e.key)
  }

  return (
    <Sider
      theme="light"
      width="256"
      collapsible={true}
      collapsed={isMenuCollapsed}
      onCollapse={onCollapse}
      breakpoint="lg"
      className={styles.menu}>
      <Logo />
      <Scrollbars
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide>
        <Menu
          theme="light"
          onClick={handleClick}
          selectedKeys={[selectedKey]}
          defaultOpenKeys={getInitialParentKeys(menuData, selectedKey)}
          mode="inline"
          className={styles.navigation}
          items={menuChildren}
        />
      </Scrollbars>
    </Sider>
  )
}

export default MenuLeft
