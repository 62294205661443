import { PUBLISHED_KEY } from '@constants/fieldKeys'

export function getProperty<T extends { key }>(key: string, properties?: T[]) {
  return properties?.find(property => property.key === key)
}

export function getPropertyValue<T extends { key; data? }>(
  key: string,
  properties?: T[],
) {
  return getProperty(key, properties)?.data?.value
}

export function checkPublishedResource(properties) {
  return !!getPropertyValue(PUBLISHED_KEY, properties)
}

export function getPropertyUpdatedAt<T extends { key; updatedAt? }>(
  key: string,
  properties?: T[],
) {
  return getProperty(key, properties)?.updatedAt
}

export function getPublishedUpdatedAt(properties) {
  return getPropertyUpdatedAt(PUBLISHED_KEY, properties)
}
