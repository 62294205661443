import { useAuth0 } from '@auth0/auth0-react'
import { fb, useFirebaseAuthStatus } from '@modules/firebase'
import React, { createContext, useContext, useEffect, useState } from 'react'

const LiveUsersContext = createContext({ users: [] })

export const useLiveUsersContext = () => useContext(LiveUsersContext)

const LiveUsersProvider = ({ children }) => {
  const [users, setUsers] = useState([])
  const { user: currentUser } = useAuth0()
  const isFirebaseAuthenticated = useFirebaseAuthStatus()
  const { db, child, ref, set, update, onDisconnect, onValue } = fb
  const liveUsersRef = ref(db, 'liveUsers')
  const currentUserRef = child(liveUsersRef, `${currentUser?.sub}`)

  useEffect(() => {
    if (!isFirebaseAuthenticated) return
    const listener = () => {
      update(currentUserRef, { path: window.location.hash })
    }

    if (currentUser) {
      update(currentUserRef, { name: currentUser.name })
      listener()
      onDisconnect(currentUserRef).remove()
      window.addEventListener('hashchange', listener)
    }

    return () => {
      window.removeEventListener('hashchange', listener)
      set(currentUserRef, null)
    }
  }, [currentUser, isFirebaseAuthenticated])

  useEffect(() => {
    if (!isFirebaseAuthenticated) return
    const unsubscribe = onValue(liveUsersRef, snap => {
      const data = snap.val()
      if (data) {
        delete data[currentUser?.sub || '']
        const users = Object.values(data)
        setUsers(users)
      }
    })

    return () => unsubscribe()
  }, [currentUser, isFirebaseAuthenticated])

  return (
    <LiveUsersContext.Provider
      value={{
        users,
      }}>
      {children}
    </LiveUsersContext.Provider>
  )
}

export default LiveUsersProvider
