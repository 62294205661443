import { DEFAULT_LANGUAGE } from '@constants'
import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
  language: DEFAULT_LANGUAGE.languageTag,
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_settings':
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

const SettingsContext = createContext()

export const useSettingsContext = () => useContext(SettingsContext)

const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setSetting = (key, value) => {
    return dispatch({
      type: 'set_settings',
      payload: {
        [key]: value,
      },
    })
  }

  const setSettings = settings => {
    return dispatch({ type: 'set_settings', payload: settings })
  }

  return (
    <SettingsContext.Provider
      value={{
        state,
        setSetting,
        setSettings,
      }}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider
