import { useContextURLConnection } from '@components/LayoutComponents/TopBar/hooks/useContextURLConnection'
import { setSelectedValue, useVerticalContext } from '@contexts/vertical'
import { getSearchParam } from '@utils'
import { memo, useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { VERTICAL_URL_KEY } from '../../constants'

const VerticalURLConnection = () => {
  const {
    loading,
    getCurrentVertical,
    setCurrentVertical,
    validate,
    getDefault,
  } = useVerticalContext()

  const isProcessed = useRef(false)
  const location = useLocation()
  useEffect(() => {
    if (isProcessed.current || loading) {
      return
    }
    isProcessed.current = true
    let urlParam = getSearchParam(location.search, VERTICAL_URL_KEY)
    if (urlParam) {
      setSelectedValue(urlParam)
    }
    // should run only once when page is opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const getCurrent = useCallback(() => {
    const currentVertical = getCurrentVertical()
    return currentVertical?.id || currentVertical
  }, [getCurrentVertical])

  useContextURLConnection({
    loading,
    getCurrent,
    setCurrent: setCurrentVertical,
    validate,
    getDefault,
    searchParamKey: VERTICAL_URL_KEY,
  })

  return null
}

export default memo(VerticalURLConnection)
