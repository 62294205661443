import { message as Message, notification as Notification } from 'antd'

/**
 * @typedef {Object} BaseMessageConfig
 * @property {string} message
 * @property {string} [description]
 * @property {number} [duration]
 * @property {"notification"|"message"} [displayType]
 */

/**
 * @typedef {Object} MessageConfigWithType
 * @property {"error"|"info"|"success"|"warning"} type
 * @typedef {BaseMessageConfig & MessageConfigWithType} MessageConfig
 */

/**
 *
 * @param {MessageConfig} message
 */

export const triggerMessage = ({
  message,
  description,
  duration,
  type,
  displayType,
  onClick = null,
} = {}) => {
  if (displayType === 'notification') {
    Notification[type]({
      message,
      description,
      duration,
      ...(onClick ? { onClick } : {}),
    })
  } else if (displayType === 'message') {
    Message[type](message)
  }
}

/**
 * @typedef {BaseMessageConfig} ErrorMessageConfig
 * @param {(ErrorMessageConfig)} error
 */

export const triggerErrorMessage = ({
  message = 'Something went wrong!',
  description = '',
  duration = 0,
  displayType = 'notification',
} = {}) => {
  return triggerMessage({
    message,
    description,
    duration,
    displayType,
    type: 'error',
  })
}

/**
 * @param {(string|ErrorMessageConfig)} error
 * @param {(ErrorMessageConfig)} config
 * @return {function(): void}
 */

export const useErrorMessage = (error, config = {}) => {
  return () => {
    if (!error) return triggerErrorMessage()
    if (typeof error === 'string') error = { message: error }
    return triggerErrorMessage(Object.assign(error, config))
  }
}

/**
 * @typedef {BaseMessageConfig} SuccessMessageConfig
 * @param {(SuccessMessageConfig)} error
 */

export const triggerSuccessMessage = ({
  message = 'Something went wrong!',
  description = '',
  duration = 4,
  displayType = 'notification',
} = {}) => {
  return triggerMessage({
    message,
    description,
    duration,
    displayType,
    type: 'success',
  })
}

/**
 * @param {(string|SuccessMessageConfig)} error
 * @param {(SuccessMessageConfig)} config
 * @return {function(): void}
 */

export const useSuccessMessage = (error, config = {}) => {
  return () => {
    if (!error) return triggerSuccessMessage()
    if (typeof error === 'string') error = { message: error }
    return triggerSuccessMessage(Object.assign(error, config))
  }
}
