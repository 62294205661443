import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'

const Auth0ProviderConfigured = ({ children }) => {
  return (
    <Auth0Provider
      domain="wdnauth.eu.auth0.com"
      clientId="OoJ7h52uB0FfQGGxksqfKcWtDoW9JwqZ"
      redirectUri={window.location.origin}
      audience="https://api.wdnsolutions.com/"
      scope="openid profile email address phone read:appointments app_metadata">
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderConfigured
