import { useAuth0 } from '@auth0/auth0-react'
import { useNotificationContext } from '@contexts/notifications'
import { fb, useFirebaseAuthStatus } from '@modules/firebase'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Notifications = ({ children }) => {
  const { db, ref, onChildAdded, orderByChild, startAt, query, onValue } = fb
  const isFirebaseAuthenticated = useFirebaseAuthStatus()
  const { pushNotification } = useNotificationContext()
  const { user } = useAuth0()
  const history = useHistory()
  const [timeOffset, setTimeOffset] = useState(null)

  const notificationsRef = ref(db, 'notifications/all')

  useEffect(() => {
    if (!user || !isFirebaseAuthenticated) return
    const unsubscribe = onValue(ref(db, '.info/serverTimeOffset'), snap => {
      const offset = snap.val()
      setTimeOffset(offset)
    })
    return () => unsubscribe()
  }, [user, isFirebaseAuthenticated])

  useEffect(() => {
    if (timeOffset === null || !isFirebaseAuthenticated || !user) return

    const queryRef = query(
      notificationsRef,
      orderByChild('createdAt'),
      startAt(timeOffset + Date.now()),
    )

    const unsubscribe = onChildAdded(queryRef, snap => {
      try {
        const data = snap.val()
        let url
        switch (data?.event?.resourceType) {
          case 'taxonomyItem':
            url = `/taxonomies/${data?.event?.id}/publisher`
            break
          case 'network':
            url = `/${data?.event?.resourceType}`
            break
          default:
            url = `/${data?.event?.resourceType}s/${
              data?.event?.templateSubType
                ? `${data?.event?.templateSubType}/`
                : ''
            }${data?.event?.id}/edit`
            break
        }

        // Prevent sending notifications to same user
        if (user?.sub !== data?.event?.user) {
          if (data?.message) {
            pushNotification({
              id: data?.event?.id,
              message: data?.message,
              type: 'info',
              onClick: () => {
                history.push({ pathname: url, search: history.location.search })
              },
            })
          }
        }
      } catch (error) {
        //
      }
    })

    return () => unsubscribe()
  }, [user, timeOffset, isFirebaseAuthenticated])

  return children
}

export default Notifications
